import { CustomTheme, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { MouseEventHandler, ReactNode, forwardRef, useEffect, useRef, useState } from 'react';
import { Link, Navigate, Outlet, ScrollRestoration, useLocation, useMatch, useNavigate } from 'react-router-dom';

import IconButton from '@/components/IconButton';
import {
  AddIcon,
  AdminIcon,
  AuditLogIcon,
  BillIcon,
  Community,
  DatabaseIcon,
  Docs,
  EditorIcon,
  ExplorerIcon,
  GraphIcon,
  GroupIcon,
  IngestionIcon,
  IntegrationIcon,
  MenuCloseIcon,
  MenuOpenIcon,
  OrgIcon,
  ReleaseNotes,
  SettingsIcon,
  SpaceIcon,
  Support,
  UserIcon,
  WorkgroupIcon,
} from '@/pages/home/icons';
import { ChevronDown, ChevronUp, LogInIcon, PackagePlusIcon, StoreIcon } from 'lucide-react';
import Logo from './logo';
import ThemeIcon from './icons/theme.svg?react';

import { getCallbackURL } from '@/lib/auth';
import {
  NavIcon,
  NavIconLevel3,
  NavLabel,
  NavLabelLevel3,
  NavLi,
  NavLink,
  NavLinkLevel2,
  NavLinkLevel3,
  NavLinkLevelPopover,
  NavOl,
} from '@/pages/home/styled';
import { WorkGroupT, canCreateWorkspaceWithNewGroup, WorkspaceQuotaRWError } from '@/pages/workgroup/type';

import { useAuth0 } from '@auth0/auth0-react';

import { Avatar, formatName } from '@/components/Avatar';
import { Drawer, DrawerBody, DrawerHeader } from '@/components/Drawer.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useOrgContext } from '@/contexts/orgContext';
import useProfile from '@/lib/useProfile';
import { isBillingAdmin, isBillingViewer, isOrgAdmin } from '@/pages/admin/user/type';
import { QuickInsightsPoll } from '@/pages/home/QuickInsightsPoll';
import { WorkspacesPoll } from '@/pages/home/WorkspacesPoll';
import { SolutionsPoll } from '@/pages/marketplace/solution/solution_poll';
import { useQueryGetGroups } from '@/pages/workgroup/hook';
import trackUtil from '@/utils/analytics-service.ts';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { Badge, COLOR, StyledRoot } from 'baseui/badge';
import { TriangleDown } from 'baseui/icon';
import { getRequestMailBody, logoutClearance } from '@/utils/utils.ts';
import { TutorialPopover } from '@/components/tutorial/TutorialPopover.tsx';
import { TutorialMenu } from '@/components/tutorial/TutorialMenu.tsx';
import { CongratulationPopover } from '@/components/tutorial/CongratulationPopover.tsx';
import { useTutorialContext } from '@/components/tutorial/tutorialContext.tsx';
import { Landing } from '@/components/landing/Landing.tsx';
import { Tutorial } from '@/components/tutorial/type.ts';
import { Tutorials } from '@/components/tutorial/mock.tsx';
import {
  StyledTable,
  StyledTableHead,
  StyledTableHeadRow,
  StyledTableHeadCell,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableBodyCell,
} from 'baseui/table-semantic';

import FeedbackDrawer from '@/pages/home/feedback/FeedbackDrawer';
import { MdOutlineFeedback } from 'react-icons/md';
import { useAtom } from 'jotai';
import { feedbackOpenAtom } from '@/pages/home/feedback/atom';
import LegalTerms from '@/components/landing/LegalTerms.tsx';
import { useIsOrgCanUseCopilot } from '@/hooks/useIsOrgCanUseCopilot';
import { useTheme } from '@/contexts/themeContext';
import eventemitter from '@/lib/eventEmitter';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import { Popover, TRIGGER_TYPE, PLACEMENT, PopoverOverrides } from 'baseui/popover';
import { StatefulPopover as StatefulToolTipsPopover } from '@tigergraph/app-ui-lib/popover';
import mediaQuery from '@/lib/breakpoints';
import { MyOrg } from '@/lib/models';
import { useAdminDashboardPermission } from '@/admin/hooks/useAdminDashboardPermission';
import { useWorkspaceContext } from '@/contexts/workspaceContext';

const key = 'navigation-close';
const statusKey = 'navigation-status';
// user: user close or open the navigation
// system: system close or close the navigation
type NavBarStatus = 'user' | 'system';

export default function Layout({ isAdminDashboardPage = false }: { isAdminDashboardPage?: boolean }) {
  const [css, theme] = useStyletron();

  const [navBarStatus, setNavBarStatus] = useState<NavBarStatus>(
    localStorage.getItem(statusKey) === 'user' ? 'user' : 'system'
  );
  const [closeNavBar, setCloseNavBar] = useState(localStorage.getItem(key) === 'true' || false);
  const location = useLocation();
  const isBeta = true;

  useEffect(() => {
    // trigger dashboard grid re-layout
    window.dispatchEvent(new CustomEvent('resize'));
    localStorage.setItem(key, `${closeNavBar}`);
  }, [closeNavBar]);

  useEffect(() => {
    // close user guide in GSQL Editor
    const isEditor = location.pathname.includes('/editor');
    if (isEditor) {
      setCloseNavBar(true);
    }
  }, [location]);

  useEffect(() => {
    const isEditor = location.pathname.includes('/editor');
    if (!isEditor && navBarStatus === 'system') {
      setCloseNavBar(false);
    }
    localStorage.setItem(statusKey, navBarStatus);
  }, [navBarStatus, location]);

  const { currentOrg, userInfo } = useOrgContext();
  const isOrgCanUseCopilot = useIsOrgCanUseCopilot();
  const { canUseDashboard, isInitialized } = useAdminDashboardPermission();

  const showAdmin = isOrgAdmin(userInfo.roles) || isBillingAdmin(userInfo.roles) || isBillingViewer(userInfo.roles);

  if (isAdminDashboardPage && isInitialized && !canUseDashboard) {
    return <Navigate to="/noPermission" replace />;
  }

  return (
    <div
      className={css({
        display: 'flex',
        height: '100vh',
        backgroundColor: theme.colors['background.primary'],
        color: theme.colors['text.primary'],
      })}
    >
      <div
        className={css({
          borderRight: `1px solid ${theme.colors['border.tertiary']}`,
          width: closeNavBar ? '64px' : '240px',
          [mediaQuery.large]: {
            width: closeNavBar ? '64px' : '306px',
          },
          flexShrink: 0,
          flexGrow: 0,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          color: theme.colors['navigation.text'],
          backgroundColor: theme.colors['background.secondary'],
        })}
      >
        <div
          className={css({
            borderBottom: `1px solid ${theme.colors.divider}`,
            marginTop: '8px',
            padding: closeNavBar ? '12px 20px' : '8px 16px',
            display: 'flex',
            position: 'relative',
          })}
        >
          {closeNavBar || !isBeta ? null : (
            <div
              className={css({
                left: '-20px',
                top: '-8px',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '90px',
                height: '16px',
                transform: 'rotate(-45deg)',
                padding: '0 19px',
                backgroundColor: '#EE7419',
              })}
            >
              <span
                className={css({
                  fontSize: '10px',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  color: '#FFFFFE',
                  position: 'relative',
                  left: '-11px',
                  letterSpacing: '0.5px',
                  transform: 'rotate(0.419deg)',
                })}
              >
                Beta
              </span>
            </div>
          )}

          {closeNavBar ? null : (
            <div
              className={css({
                marginRight: 'auto',
                display: 'flex',
                alignItems: 'center',
                height: '32px',
              })}
            >
              <Link to="/" aria-label="Home">
                <Logo />
              </Link>
              {!isAdminDashboardPage &&
                (currentOrg?.logo_url ? (
                  <img
                    src={currentOrg.logo_url}
                    className={css({
                      borderLeft: `1px solid ${theme.colors.divider}`,
                      height: '32px',
                      display: 'inline-block',
                      paddingLeft: '8px',
                    })}
                  />
                ) : (
                  <div
                    className={css({
                      borderLeft: `1px solid ${theme.colors.divider}`,
                      display: 'inline-block',
                      paddingLeft: '8px',
                      fontSize: '14px',
                      maxWidth: '120px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    })}
                  >
                    {currentOrg?.org_display_name || currentOrg.org_name}
                  </div>
                ))}
            </div>
          )}
          <IconButton
            className={css({
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
            onClick={() => {
              setCloseNavBar((prev) => !prev);
              setNavBarStatus('user');
            }}
          >
            {closeNavBar ? <MenuOpenIcon /> : <MenuCloseIcon />}
          </IconButton>
        </div>
        <nav
          className={css({
            flexBasis: 0,
            minWidth: 0,
            flexGrow: 1,
            paddingTop: '12px',
            alignSelf: 'stretch',
            overflowY: 'auto',
            overscrollBehavior: 'contain',
          })}
        >
          <ol
            className={css({
              margin: 0,
              padding: 0,
              listStyle: 'none',
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              height: '100%',
            })}
          >
            {isAdminDashboardPage ? (
              <>
                <ListItem to="/dashboard/workspace" icon={<AdminIcon />} closeNavBar={closeNavBar}>
                  Workspace
                </ListItem>
                <ListItem to="/dashboard/org" icon={<OrgIcon className="w-4" />} closeNavBar={closeNavBar}>
                  Organization
                </ListItem>
              </>
            ) : (
              <>
                <GroupItems closeNavBar={closeNavBar} />
                <StatefulToolTipsPopover
                  triggerType={TRIGGER_TYPE.hover}
                  content="Design Schema"
                  ignoreBoundary={true}
                  placement={PLACEMENT.topLeft}
                  overrides={statefulPopoverTooltipOverrides(!closeNavBar)}
                >
                  <ListItem
                    to="/design-schema"
                    onClick={() => {
                      trackUtil.trackEnterSchemaDesigner();
                    }}
                    icon={<GraphIcon />}
                    closeNavBar={closeNavBar}
                  >
                    Design Schema
                  </ListItem>
                </StatefulToolTipsPopover>
                <StatefulToolTipsPopover
                  triggerType={TRIGGER_TYPE.hover}
                  content="Load Data"
                  ignoreBoundary={true}
                  placement={PLACEMENT.topLeft}
                  overrides={statefulPopoverTooltipOverrides(!closeNavBar)}
                >
                  <ListItem
                    to="/ingestion"
                    onClick={() => {
                      trackUtil.trackEnterDataIngestion();
                    }}
                    icon={<IngestionIcon />}
                    closeNavBar={closeNavBar}
                  >
                    Load Data
                  </ListItem>
                </StatefulToolTipsPopover>
                <StatefulToolTipsPopover
                  triggerType={TRIGGER_TYPE.hover}
                  content="GSQL Editor"
                  ignoreBoundary={true}
                  placement={PLACEMENT.topLeft}
                  overrides={statefulPopoverTooltipOverrides(!closeNavBar)}
                >
                  <ListItem
                    to="/editor"
                    onClick={() => {
                      trackUtil.trackEnterGSQLEditor('home');
                    }}
                    icon={<EditorIcon />}
                    closeNavBar={closeNavBar}
                  >
                    GSQL Editor
                  </ListItem>
                </StatefulToolTipsPopover>
                <StatefulToolTipsPopover
                  triggerType={TRIGGER_TYPE.hover}
                  content="Explore Graph"
                  ignoreBoundary={true}
                  placement={PLACEMENT.topLeft}
                  overrides={statefulPopoverTooltipOverrides(!closeNavBar)}
                >
                  <ListItem
                    to="/explore"
                    onClick={() => {
                      trackUtil.trackEnterExploreGraph();
                    }}
                    icon={
                      <div>
                        <ExplorerIcon />
                        {closeNavBar && isOrgCanUseCopilot ? (
                          <span
                            className={css({
                              backgroundColor: '#FF6D00',
                              fontSize: '6px',
                              padding: '1px 2px',
                              color: '#FFFFFE',
                              fontWeight: 400,
                              position: 'absolute',
                              left: '0px',
                              top: 0,
                            })}
                          >
                            CoPilot
                          </span>
                        ) : null}
                      </div>
                    }
                    closeNavBar={closeNavBar}
                  >
                    Explore Graph
                    {isOrgCanUseCopilot && (
                      <span
                        className={css({
                          backgroundColor: '#FF6D00',
                          borderRadius: '2px',
                          fontSize: '10px',
                          padding: '2px 4px',
                          color: '#FFFFFE',
                          marginLeft: '8px',
                          position: 'relative',
                          top: '-2px',
                          fontWeight: 400,
                        })}
                      >
                        CoPilot
                      </span>
                    )}
                  </ListItem>
                </StatefulToolTipsPopover>
                <MarketplaceListItem closeNavBar={closeNavBar} />
                {showAdmin ? <AdminListItem closeNavBar={closeNavBar} /> : null}
              </>
            )}
          </ol>
        </nav>
        <Bottom closeNavBar={closeNavBar} isBeta={isBeta} isAdminDashboardPage={isAdminDashboardPage} />
      </div>
      <main
        className={css({
          flexBasis: 0,
          flexGrow: 1,
          height: '100%',
          overflowY: 'auto',
          overscrollBehavior: 'contain',
        })}
      >
        <Outlet />
      </main>
      <Landing />
      <ScrollRestoration />
      <WorkspacesPoll />
      <QuickInsightsPoll />
      <SolutionsPoll />
    </div>
  );
}

function LinkItem({ icon, label }: { icon: ReactNode; label: string }) {
  const { userInfo, currentOrg } = useOrgContext();
  const [css, theme] = useStyletron();
  let url = '';
  switch (label) {
    case 'Community': {
      url = 'https://dev.tigergraph.com/forum/c/tigergrah-cloud/';
      break;
    }
    case 'Feedback': {
      url = 'https://forms.gle/PbdwSBpRKbwabAqE7';
      break;
    }
    case 'Support': {
      url = `mailto:beta-support@tigergraph.com?subject=TigerGraph Cloud 4 Beta Support - ${userInfo.email} - ${
        currentOrg.org_id
      } - ${currentOrg.org_name}&body=${getRequestMailBody()}`;
      break;
    }
    case 'Docs': {
      url = 'https://docs.tigergraph.com/';
      break;
    }
    case 'Release notes': {
      url = 'https://docs.tigergraph.com/cloud/current/release-notes/';
      break;
    }
  }

  return (
    <IconButton
      $style={{
        width: '100%',
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        fontSize: '12px',
        padding: '8px 12px 8px 0px',
        color: theme.colors['text.primary'],
      }}
      onClick={() => {
        window.open(url, '_blank');
      }}
    >
      {icon} {label}
    </IconButton>
  );
}

function Links({ closeNavBar }: { closeNavBar: boolean }) {
  const [css, theme] = useStyletron();
  const [isTutorialPopoverOpen, setIsTutorialPopoverOpen] = useState(false);
  const tutorialRef = useRef<{
    clearState: () => void;
    stepToSubChapter: (tutorial?: Tutorial) => void;
    stepToChapter: (routerKey?: string, tutorialID?: string) => void;
  }>(null);
  const { shouldShowCongratulationModal, setShouldShowCongratulationModal } = useTutorialContext();

  const [isFeedbackOpen, setIsFeedbackOpen] = useAtom(feedbackOpenAtom);

  useEffect(() => {
    eventemitter.on('tutorial', (routerKey, tutorialID) => {
      setIsTutorialPopoverOpen(true);
      tutorialRef.current?.stepToChapter(routerKey, tutorialID);
    });

    eventemitter.on('feedback', () => {
      setIsFeedbackOpen(true);
    });

    return () => {
      eventemitter.off('tutorial');
      eventemitter.off('feedback');
    };
  }, [setIsFeedbackOpen]);

  return (
    <>
      {closeNavBar ? null : (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '8px',
            borderTop: `1px solid ${theme.colors.divider}`,
          })}
        >
          <span
            className={css({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '4px',
              width: '100%',
              paddingTop: '8px',
              paddingBottom: '8px',
            })}
          >
            <LinkItem icon={<Community />} label="Community" />
            <IconButton $style={{ width: '100%' }}>
              <span
                onClick={(event) => {
                  setIsFeedbackOpen(true);
                }}
                className={css({
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  fontSize: '12px',
                  padding: '8px 12px 8px 0px',
                  color: theme.colors['text.primary'],
                })}
              >
                <MdOutlineFeedback size={16} /> Feedback
              </span>
            </IconButton>
            <LinkItem icon={<Docs />} label={'Docs'} />
            <LinkItem icon={<ReleaseNotes />} label={'Release notes'} />
            <LinkItem icon={<Support />} label={'Support'} />
            <TutorialMenu
              onClick={() => {
                if (isTutorialPopoverOpen) {
                  tutorialRef.current?.clearState();
                  setIsTutorialPopoverOpen(false);
                } else {
                  setIsTutorialPopoverOpen(true);
                }
              }}
            />
          </span>
          <CongratulationPopover
            showModal={shouldShowCongratulationModal}
            handleClose={() => {
              setShouldShowCongratulationModal(false);
            }}
            onTakeTour={() => {
              setShouldShowCongratulationModal(false);
              setIsTutorialPopoverOpen(true);
              const startTutorial = Tutorials.find((tutorial) => tutorial.id === '0');
              tutorialRef.current?.stepToSubChapter(startTutorial);
            }}
          />
        </div>
      )}
      <TutorialPopover
        ref={tutorialRef}
        isOpen={isTutorialPopoverOpen}
        onClose={() => setIsTutorialPopoverOpen(false)}
        closeNavBar={closeNavBar}
      />
      <FeedbackDrawer isShow={isFeedbackOpen} onClose={() => setIsFeedbackOpen(false)} />
    </>
  );
}

function Bottom({
  closeNavBar,
  isBeta,
  isAdminDashboardPage,
}: {
  closeNavBar: boolean;
  isBeta: boolean;
  isAdminDashboardPage: boolean;
}) {
  const [css, theme] = useStyletron();
  const { currentOrg, orgList, setCurrentOrg } = useOrgContext();
  const { loginWithRedirect } = useAuth0();
  const [showSwitchDrawer, setShowSwitchDrawer] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const { canUseDashboard } = useAdminDashboardPermission();
  const { toggleTheme } = useTheme();

  const { data: profile } = useProfile();

  let userEmail = '';
  if (profile) {
    userEmail = profile.email;
  }

  const { logout } = useAuth0();

  const handleSwitchClassic = () => {
    let classicUrl = '';
    if (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'staging') {
      classicUrl = 'https://tgcloud.io/';
    } else {
      classicUrl = 'https://tgcloud-dev.com/';
    }
    window.location.href = classicUrl;
  };

  const handleSwithOrg = (org: MyOrg) => {
    setCurrentOrg(org);
    loginWithRedirect({
      authorizationParams: {
        organization: org.org_id,
        redirect_uri: getCallbackURL('/'),
      },
    });
  };

  return (
    <div
      className={css({
        padding: closeNavBar ? '16px 16px 16px 16px' : '16px 16px 16px 16px',
      })}
    >
      <Drawer
        isOpen={showSwitchDrawer}
        onClose={() => setShowSwitchDrawer(false)}
        overrides={{
          Root: {
            style: {
              zIndex: 100,
            },
          },
        }}
      >
        <DrawerHeader>Switch Organization</DrawerHeader>
        <DrawerBody
          $style={{
            maxHeight: 'calc(100vh - 57px)',
            marginTop: '8px',
            paddingTop: '0',
            paddingBottom: '8px',
            overflowY: 'auto',
          }}
        >
          <StyledRoot>
            <StyledTable>
              <StyledTableHead>
                <StyledTableHeadRow>
                  <StyledTableHeadCell className="w-[200px]">Organization Id</StyledTableHeadCell>
                  <StyledTableHeadCell>Display Name</StyledTableHeadCell>
                  <StyledTableHeadCell className="w-[50px]">Action</StyledTableHeadCell>
                </StyledTableHeadRow>
              </StyledTableHead>
              <StyledTableBody>
                {orgList?.map((org) => (
                  <StyledTableBodyRow className="group" key={org.org_id}>
                    <StyledTableBodyCell>
                      {org.org_name}
                      {org.org_id === currentOrg.org_id && (
                        <Badge
                          content={'Current'}
                          hierarchy={'secondary'}
                          color={COLOR.positive}
                          overrides={{
                            Badge: {
                              style: {
                                padding: '4px 12px',
                                borderRadius: '20px',
                                height: '24px',
                                marginLeft: '8px',
                              },
                            },
                          }}
                        />
                      )}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>{org.org_display_name || org.org_name}</StyledTableBodyCell>
                    <StyledTableBodyCell>
                      <div className="opacity-0 group-hover:opacity-100">
                        <div className="flex space-x-1">
                          <IconButton
                            tabIndex={-1}
                            className="flex items-center"
                            disabled={org.org_id === currentOrg.org_id}
                            onClick={() => {
                              if (location.pathname.includes('/editor')) {
                                window.dispatchEvent(
                                  new CustomEvent('check-unsaved-files', {
                                    detail: { onConfirm: () => handleSwithOrg(org) },
                                  })
                                );
                              } else {
                                handleSwithOrg(org);
                              }
                            }}
                          >
                            <LogInIcon className="cursor-pointer" />
                          </IconButton>
                        </div>
                      </div>
                    </StyledTableBodyCell>
                  </StyledTableBodyRow>
                ))}
              </StyledTableBody>
            </StyledTable>
          </StyledRoot>
        </DrawerBody>
      </Drawer>
      {/* {!closeNavBar && isBeta && !isAdminDashboardPage ? (
        <div
          className={css({
            marginBottom: '16px',
            padding: '12px 20px 12px 22px',
            backgroundColor: '#FFFAF6',
            backgroundImage: `url(${Bg})`,
          })}
        >
          <div
            className={css({
              fontSize: '14px',
              lineHeight: '24px',
              fontWeight: 600,
              fontFamily: 'Urbanist',
              color: '#3F5870',
            })}
          >
            Welcome to <br />
            TigerGraph Cloud 4 Beta!
          </div>
          <div
            className={css({
              fontSize: '10px',
              lineHeight: '16px',
              color: '#909090',
            })}
          >
            Try out our latest features and improvements.
          </div>
        </div>
      ) : null} */}
      {!isAdminDashboardPage && <Links closeNavBar={closeNavBar} />}
      <div
        className={css({
          paddingTop: '16px',
          borderTop: `1px solid ${theme.colors.divider}`,
          display: 'flex',
          justifyContent: closeNavBar ? 'center' : 'space-between',
        })}
      >
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <IconButton
              className={css({
                display: 'flex',
                flex: '1 1 auto',
                width: 0,
                alignItems: 'center',
              })}
            >
              <Avatar className={css({ flexShrink: 0 })} size="compact">
                {profile ? formatName(profile) : ''}
              </Avatar>
              {!closeNavBar ? (
                <>
                  <span
                    className={css({
                      paddingLeft: '8px',
                      paddingRight: '4px',
                      fontSize: '14px',
                      lineHeight: 1.4,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      textAlign: 'left',
                      flex: 1,
                    })}
                  >
                    {userEmail}
                  </span>
                  <TriangleDown />
                </>
              ) : null}
            </IconButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {!isAdminDashboardPage ? (
              <>
                <DropdownMenuItem
                  onSelect={() => {
                    handleSwitchClassic();
                  }}
                >
                  <span>Go to TigerGraph Cloud Classic</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => {
                    setShowTerms(true);
                  }}
                >
                  <span>Terms and Conditions</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => {
                    setShowSwitchDrawer(true);
                  }}
                >
                  <span>Switch Organization</span>
                </DropdownMenuItem>
                {canUseDashboard && (
                  <DropdownMenuItem
                    onSelect={() => {
                      window.open('/dashboard', '_blank');
                    }}
                  >
                    <span>Admin Dashboard</span>
                  </DropdownMenuItem>
                )}
              </>
            ) : null}
            <DropdownMenuItem
              onSelect={() => {
                logoutClearance();
                logout({
                  logoutParams: { returnTo: window.location.origin },
                });
              }}
            >
              Logout
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        {!closeNavBar && (
          <IconButton className={css({ marginLeft: '3px' })} onClick={toggleTheme}>
            <ThemeIcon />
          </IconButton>
        )}
        <LegalTerms isViewMode={true} showModal={showTerms} onClose={() => setShowTerms(false)} />
      </div>
    </div>
  );
}

export const ListItem = forwardRef<
  HTMLLIElement,
  {
    to: string;
    children: ReactNode;
    icon: ReactNode;
    level?: 1 | 2 | 3 | 'popover';
    closeNavBar?: boolean;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
  }
>(({ to, children, icon, level = 1, closeNavBar, onClick, ...props }, ref) => {
  let Link = NavLink;
  let Label = NavLabel;
  let Icon = NavIcon;
  if (level === 2) {
    Link = NavLinkLevel2;
  }
  if (level === 3) {
    Link = NavLinkLevel3;
    Label = NavLabelLevel3;
    // @ts-ignore
    Icon = NavIconLevel3;
  }
  if (level === 'popover') {
    Link = NavLinkLevelPopover;
  }

  const match = useMatch(to);
  return (
    <NavLi ref={ref} {...props}>
      <Link to={to} onClick={onClick}>
        <Icon to={to}>{icon}</Icon>
        {!closeNavBar ? <Label $match={!!match}>{children}</Label> : null}
      </Link>
    </NavLi>
  );
});

function MarketplaceListItem({ closeNavBar }: { closeNavBar: boolean }) {
  const location = useLocation();
  const [showSubItems, setShowSubItems] = useState(location.pathname.startsWith('/admin'));

  const content = ({ close, popover = true }: { close?: () => void; popover?: boolean }) => {
    return (
      <NavOl id="marketplace-list">
        <ListItem
          to="/marketplace/solutions"
          icon={<IntegrationIcon />}
          onClick={close}
          level={popover ? 'popover' : 2}
        >
          Solutions
        </ListItem>
        <ListItem
          to="/marketplace/addons"
          icon={<PackagePlusIcon size="16px" />}
          onClick={close}
          level={popover ? 'popover' : 2}
        >
          Add-Ons
        </ListItem>
      </NavOl>
    );
  };

  const [showPopover, setShowPopover] = useState(false);
  const onClose = () => {
    setShowPopover(false);
  };

  return (
    <NavLi>
      {!closeNavBar ? (
        <NavLink
          to="/marketplace"
          onClick={(e) => {
            e.preventDefault();
            setShowSubItems(!showSubItems);

            setTimeout(() => {
              document.getElementById('marketplace-list')?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
        >
          <NavIcon to="/marketplace">
            <StoreIcon size="16px" />
          </NavIcon>
          <NavLabel $match={false} $style={{ flexGrow: 1, maxWidth: 'none' }}>
            Marketplace
          </NavLabel>
          {!showSubItems ? <ChevronDown size="16px" /> : <ChevronUp size="16px" />}
        </NavLink>
      ) : (
        <Popover
          triggerType={TRIGGER_TYPE.hover}
          content={content({
            popover: true,
            close: () => setShowPopover(false),
          })}
          ignoreBoundary={true}
          placement={PLACEMENT.rightTop}
          overrides={statefulPopOverrides}
          isOpen={showPopover}
          onClickOutside={onClose}
          onEsc={onClose}
          onMouseEnter={() => {
            setShowPopover(true);
          }}
          onMouseLeave={onClose}
          autoFocus={false}
        >
          <NavLink
            to="/marketplace"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <NavIcon to="/marketplace">
              <StoreIcon size="16px" />
            </NavIcon>
          </NavLink>
        </Popover>
      )}
      {showSubItems && !closeNavBar ? content({ popover: false }) : null}
    </NavLi>
  );
}

function AdminListItem({ closeNavBar }: { closeNavBar: boolean }) {
  const location = useLocation();
  const [showSubItems, setShowSubItems] = useState(location.pathname.startsWith('/admin'));
  const { userInfo } = useOrgContext();
  const isOrgAdminRole = isOrgAdmin(userInfo.roles);

  const content = ({ close, popover = true }: { close?: () => void; popover?: boolean }) => {
    return (
      <NavOl id="admin-list">
        <NavOl>
          {isOrgAdminRole ? (
            <ListItem to="/admin/users" icon={<UserIcon />} onClick={close} level={popover ? 'popover' : 2}>
              Users
            </ListItem>
          ) : null}
          {isOrgAdminRole ? (
            <ListItem to="/admin/audit-log" icon={<AuditLogIcon />} onClick={close} level={popover ? 'popover' : 2}>
              Audit Log
            </ListItem>
          ) : null}
          <ListItem to="/admin/bills" icon={<BillIcon />} onClick={close} level={popover ? 'popover' : 2}>
            Billing
          </ListItem>
          {isOrgAdminRole ? (
            <>
              <ListItem to="/admin/settings" icon={<SettingsIcon />} onClick={close} level={popover ? 'popover' : 2}>
                Setting
              </ListItem>
            </>
          ) : null}
        </NavOl>
      </NavOl>
    );
  };

  const [showPopover, setShowPopover] = useState(false);
  const onClose = () => {
    setShowPopover(false);
  };

  return (
    <NavLi>
      {!closeNavBar ? (
        <NavLink
          to="/admin"
          onClick={(e) => {
            e.preventDefault();
            setShowSubItems(!showSubItems);
            setTimeout(() => {
              document.getElementById('admin-list')?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
        >
          <NavIcon to="/admin">
            <AdminIcon />
          </NavIcon>
          <NavLabel $match={false} $style={{ flexGrow: 1, maxWidth: 'none' }}>
            Admin
          </NavLabel>
          {!showSubItems ? <ChevronDown size="16px" /> : <ChevronUp size="16px" />}
        </NavLink>
      ) : (
        <Popover
          triggerType={TRIGGER_TYPE.hover}
          content={content({
            popover: true,
            close: () => setShowPopover(false),
          })}
          ignoreBoundary={true}
          placement={PLACEMENT.rightTop}
          overrides={statefulPopOverrides}
          isOpen={showPopover}
          onClickOutside={onClose}
          onEsc={onClose}
          onMouseEnter={() => {
            setShowPopover(true);
          }}
          onMouseLeave={onClose}
          autoFocus={false}
        >
          <NavLink
            to="/admin"
            onClick={(e) => {
              e.preventDefault();
              setShowSubItems(!showSubItems);
            }}
          >
            <NavIcon to="/admin">
              <AdminIcon />
            </NavIcon>
          </NavLink>
        </Popover>
      )}
      {showSubItems && !closeNavBar ? content({ popover: false }) : null}
    </NavLi>
  );
}

function GroupItems({ closeNavBar }: { closeNavBar: boolean }) {
  const [css, theme] = useStyletron();
  const match = useMatch('/groups');
  const navigate = useNavigate();

  const { userInfo } = useOrgContext();
  const isOrgAdminRole = isOrgAdmin(userInfo.roles);

  const { isLoading, data } = useQueryGetGroups({
    refetchOnWindowFocus: true,
    refetchInterval: 30 * 3000,
  });

  const { orgQuota } = useWorkspaceContext();
  const canCreateGroup = orgQuota && canCreateWorkspaceWithNewGroup(orgQuota);

  const [showSubItems, setShowSubItems] = useState(false);

  const groups = data?.Result || [];

  const createButton = (enableCreate: boolean) => {
    return (
      <IconButton
        // @ts-ignore
        onClick={(e) => {
          if (enableCreate) {
            e.stopPropagation();
            e.preventDefault();
            navigate('/groups/new');
          }
        }}
        style={{
          opacity: enableCreate ? 1 : 0.5,
        }}
      >
        <AddIcon />
      </IconButton>
    );
  };

  const loading = (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '8px',
      })}
    >
      <Spinner $size="16px" $borderWidth="2px" $color={theme.colors.primary900} />
    </div>
  );

  const content = ({ close, popover = true }: { close?: () => void; popover?: boolean }) => {
    return (
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          ...(popover ? { gap: '4px', maxHeight: '600px', overflowY: 'auto' } : {}),
        })}
      >
        {groups.map((group) => (
          <GroupItem group={group} key={group.workgroup_id} close={close} popover={popover} />
        ))}
        {groups.length === 0 && popover ? (
          <span
            className={css({
              padding: '8px 12px',
            })}
          >
            {isLoading ? loading : 'Get started by creating your first workgroup and workspace.'}
          </span>
        ) : null}
      </div>
    );
  };

  const [showPopover, setShowPopover] = useState(false);
  const onClose = () => {
    setShowPopover(false);
  };

  const handleNaviageToCreateWorkgroups = () => {
    // if no group, redirect to create group page
    if (!isLoading && groups.length === 0) {
      navigate('/groups');
    }
  };

  return (
    <NavLi
      className={css({
        position: 'relative',
      })}
    >
      {!closeNavBar ? (
        <NavLink
          to="/groups"
          onClick={(e) => {
            e.preventDefault();
            setShowSubItems(!showSubItems);

            handleNaviageToCreateWorkgroups();
          }}
        >
          <NavIcon to="/groups">
            <WorkgroupIcon />
          </NavIcon>
          <NavLabel $match={!!match} $style={{ marginRight: '6px' }}>
            Workgroups
          </NavLabel>
          {isOrgAdminRole && orgQuota ? (
            canCreateGroup ? (
              createButton(true)
            ) : (
              <StatefulTipsPopover
                content={<WorkspaceQuotaRWError orgQuota={orgQuota} />}
                triggerType={TRIGGER_TYPE.hover}
                placement="left"
                focusLock={true}
                autoFocus={false}
                ignoreBoundary={true}
                overrides={{
                  Body: {
                    style: {
                      maxWidth: '450px',
                    },
                  },
                }}
              >
                {createButton(false)}
              </StatefulTipsPopover>
            )
          ) : null}
        </NavLink>
      ) : (
        <Popover
          triggerType={TRIGGER_TYPE.hover}
          content={content({
            popover: true,
            close: () => setShowPopover(false),
          })}
          ignoreBoundary={true}
          placement={PLACEMENT.rightTop}
          overrides={statefulPopOverrides}
          isOpen={showPopover}
          onClickOutside={onClose}
          onEsc={onClose}
          onMouseEnter={() => {
            setShowPopover(true);
          }}
          onMouseLeave={onClose}
          autoFocus={false}
        >
          <NavLink
            to="/groups"
            onClick={(e) => {
              e.preventDefault();

              handleNaviageToCreateWorkgroups();
            }}
          >
            <NavIcon to="/groups">
              <WorkgroupIcon />
            </NavIcon>
          </NavLink>
        </Popover>
      )}
      {groups.length > 0 ? (
        <IconButton
          className={css({
            position: 'absolute',
            top: '12px',
            right: '12px',
          })}
          onClick={() => {
            setShowSubItems(!showSubItems);
          }}
        >
          {!closeNavBar && (!showSubItems ? <ChevronDown size="16px" /> : <ChevronUp size="16px" />)}
        </IconButton>
      ) : null}
      {!closeNavBar && showSubItems ? content({ popover: false }) : null}
      {isLoading && !closeNavBar ? loading : null}
    </NavLi>
  );
}

function GroupItem({ group, popover, close }: { group: WorkGroupT; close?: () => void; popover?: boolean }) {
  const [css] = useStyletron();
  const to = `/groups/${group.workgroup_id}`;
  const match = useMatch(to);

  const [showSubItems, setShowSubItems] = useState(false);

  const Component = popover ? NavLinkLevelPopover : NavLinkLevel2;

  return (
    <NavOl
      className={css({
        position: 'relative',
        rowGap: '0px',
      })}
    >
      <Component to={to} onClick={close}>
        <NavIcon to={to}>
          <GroupIcon />
        </NavIcon>
        <NavLabel $match={!!match} className={css({ flex: '1 1 auto', width: 0, minWidth: '100px' })}>
          {group.name}
        </NavLabel>
        {group.workspaces.length + group.tg_databases.length > 0 && !popover ? (
          <IconButton
            onClick={() => {
              setShowSubItems(!showSubItems);
            }}
          >
            {!showSubItems ? <ChevronDown size="16px" /> : <ChevronUp size="16px" />}
          </IconButton>
        ) : null}
      </Component>
      {showSubItems && !popover ? (
        <NavOl>
          {group.workspaces.map((space) => (
            <ListItem
              key={space.name}
              to={`/groups/${group.workgroup_id}?tab=workspaces`}
              icon={<SpaceIcon />}
              level={3}
            >
              {space.name}
            </ListItem>
          ))}
          {group.tg_databases.map((database) => (
            <ListItem
              key={database.name}
              to={`/groups/${group.workgroup_id}?tab=databases`}
              icon={<DatabaseIcon />}
              level={3}
            >
              {database.name}
            </ListItem>
          ))}
        </NavOl>
      ) : null}
    </NavOl>
  );
}

const statefulPopOverrides: PopoverOverrides = {
  Body: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        marginLeft: '0px',
        boxShadow: theme.colors['shadow.popup'],
      };
    },
  },
  Inner: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        backgroundColor: theme.colors['background.primary'],
      };
    },
  },
};

const statefulPopoverTooltipOverrides = (hidden: boolean): PopoverOverrides => {
  return {
    Body: {
      style: {
        marginLeft: '8px',
        display: hidden ? 'none' : 'block',
      },
    },
  };
};
