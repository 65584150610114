import Home from '@/pages/home/home';
import HomeLayout from '@/pages/home/layout';
import WorkGroup from '@/pages/workgroup';
import Groups from '@/pages/workgroup/Groups';
import GroupLayout from '@/pages/workgroup/form/GroupLayout';
import SpaceLayout from '@/pages/workgroup/form/SpaceLayout';
import WorkGroupForm from '@/pages/workgroup/form/WorkGroupForm';
import WorkSpaceForm from '@/pages/workgroup/form/WorkSpaceForm';
import { Auth0Provider, IdToken, useAuth0 } from '@auth0/auth0-react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { AxiosError } from 'axios';
import { Suspense, lazy, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { ConfigProvider, useConfig } from '@/lib/config';
import { axiosCluster, axiosController, axiosOrgService } from '@/lib/network';

import StyledToasterContainer from '@/components/styledToasterContainer';
import { ID_TOKEN_KEY, WorkspaceProvider } from '@/contexts/workspaceContext';
import { getCallbackURL } from '@/lib/auth';
import Callback from '@/pages/callback';
import version from './version.json';

import { LoadingIndicator } from '@/components/loading-indicator';
import { UserGuideProvider } from '@/components/userguide/userGuideContext';
import { GraphEditorProvider } from '@/contexts/graphEditorContext';
import { OrgProvider, useOrgContext } from '@/contexts/orgContext';
import { SchemaDesignerProvider } from '@/contexts/schemaDesignerContext';
import { MyOrgList, UserInfo } from '@/lib/models';
import Settings from '@/pages/admin/settings';
import { NegativeNotification } from '@/components/StyledNotification.tsx';
import LegalTerms from '@/components/landing/LegalTerms.tsx';
import ProtectedRoutes from '@/components/ProtectedRoutes.tsx';
import { AuthorizationParams } from '@auth0/auth0-spa-js/src/global.ts';
import { getErrorMessage, getMainDomain, logoutClearance } from '@/utils/utils.ts';
import { isBillingAdmin, isBillingViewer, isOrgAdmin } from '@/pages/admin/user/type.ts';
import NoPermission from '@/components/NoPermission.tsx';
import { EmailSend } from '@/components/landing/EmailSend.tsx';
import { TutorialProvider } from '@/components/tutorial/tutorialContext.tsx';
import { ThemeProvider, useTheme } from '@/contexts/themeContext.tsx';
import { getAxiosInstance } from '@tigergraph/tools-models';
import { ValidateCredit } from '@/pages/misc/CreditZero.tsx';
import Fallback from '@/components/Fallback.tsx';

const Ingestion = lazy(
  () =>
    import(
      /* webpackChunkName: "ingestion" */
      './pages/ingestion/index.tsx'
    )
);

const Explore = lazy(
  () =>
    import(
      /* webpackChunkName: "explore" */
      './pages/explore/index.tsx'
    )
);

const Solution = lazy(
  () =>
    import(
      /* webpackChunkName: "solution" */
      './pages/marketplace/solution/index.tsx'
    )
);

const Addon = lazy(
  () =>
    import(
      /* webpackChunkName: "addon" */
      './pages/marketplace/addon/index.tsx'
    )
);

const AuditLog = lazy(
  () =>
    import(
      /* webpackChunkName: "auditlog" */
      './pages/admin/auditlog/index.tsx'
    )
);

const Users = lazy(
  () =>
    import(
      /* webpackChunkName: "user" */
      './pages/admin/user/index.tsx'
    )
);

const Designer = lazy(
  () =>
    import(
      /* webpackChunkName: "designSchema" */
      './pages/designSchema/index.tsx'
    )
);

const Editor = lazy(
  () =>
    import(
      /* webpackChunkName: "editor" */
      './pages/editor/index.tsx'
    )
);

const Bill = lazy(
  () =>
    import(
      /* webpackChunkName: "bill" */
      './pages/admin/bill/index.tsx'
    )
);

const AdminWorkspaces = lazy(
  () =>
    import(
      /* webpackChunkName: "admin_workspace" */
      './admin/pages/workspace/index.tsx'
    )
);

const AdminOrg = lazy(
  () =>
    /* webpackChunkName: "admin_org" */
    import('./admin/pages/org')
);

function clearPageSpinner() {
  const ele = document.getElementById('loading');
  if (ele) {
    ele.parentNode?.removeChild(ele);
  }
}

console.log('===============================================================');
console.log(' _______                 ______                 __');
console.log(' /_  __(_)___ ____  _____/ ____/________ _____  / /_');
console.log('  / / / / __ `/ _ \\/ ___/ / __/ ___/ __ `/ __ \\/ __ \\');
console.log(' / / / / /_/ /  __/ /  / /_/ / /  / /_/ / /_/ / / / /');
console.log('/_/ /_/\\__, /\\___/_/   \\____/_/   \\__,_/ .___/_/ /_/');
console.log('     /____/                          /_/');
console.log('===============================================================');
console.log('TigerGraph Cloud ' + version.major + '.' + version.minor + '.' + version.build + '.' + version.revision);
console.log('Powered by TigerGraph');

export default function App() {
  return (
    <GraphEditorProvider>
      <OrgProvider>
        <ThemeProvider>
          <SchemaDesignerProvider>
            <StyledToasterContainer />
            <HotToast />
            <ConfigProvider>
              <Auth0Container />
            </ConfigProvider>
          </SchemaDesignerProvider>
        </ThemeProvider>
      </OrgProvider>
    </GraphEditorProvider>
  );
}

function HotToast() {
  const { theme } = useTheme();
  return (
    <Toaster
      toastOptions={{
        style: {
          wordBreak: 'break-all',
          color: theme.colors['text.primary'],
          background: theme.colors['background.primary'],
        },
        loading: {
          style: {},
        },
        success: {
          iconTheme: {
            secondary: theme.colors['notification.background.success'],
            primary: theme.colors['notification.background.success.bold'],
          },
          style: {
            background: theme.colors['notification.background.success'],
          },
        },
        error: {
          iconTheme: {
            secondary: theme.colors['notification.background.danger'],
            primary: theme.colors['notification.background.danger.bold'],
          },
          style: {
            background: theme.colors['notification.background.danger'],
          },
        },
      }}
    />
  );
}

function Auth0Container() {
  const { auth0 } = useConfig();
  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);
  const isOrgLogin = window.location.search.match(/isOrgLogin=true/);

  // organization login and invite login should use the client_id instead of lobby_client_id
  // because the backend generate the invite ticket with client_id
  const orgClientCondition = isOrgLogin || (orgMatches && inviteMatches);

  return (
    <Auth0Provider
      domain={auth0.domain}
      clientId={orgClientCondition ? auth0.client_id : auth0.lobby_client_id}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      cookieDomain={`${getMainDomain()}`}
      authorizationParams={{
        redirect_uri: orgClientCondition ? `${window.location.origin}?isOrgLogin=true` : window.location.origin,
        audience: auth0.audience,
      }}
    >
      <Pages />
    </Auth0Provider>
  );
}

function Pages() {
  const { isAuthenticated, isLoading, getIdTokenClaims, loginWithRedirect, logout } = useAuth0();
  // refer: https://github.com/auth0/auth0-spa-js/blob/main/FAQ.md#why-is-isauthenticated-returning-true-when-there-are-no-tokens-available-to-call-an-api
  // isAuthenticated is true even if the token is expired, so we need to check if the token is valid on server side
  const [isTokenValidOnServer, setIsTokenValidOnServer] = useState(false);
  const [css, theme] = useStyletron();
  const [errorInfo, setErrorInfo] = useState<string>('');

  const [idToken, setIdToken] = useState<IdToken>();
  const { userInfo, setOrgList, setCurrentOrg, currentOrg, setUserInfo } = useOrgContext();

  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);
  const isOrgLogin = url.match(/isOrgLogin=true/);
  const errorMatches = url.match(/error=([^&]+)/);
  const errorInfoMatches = url.match(/error_description=([^&]+)/);
  const logoutMatches = url.match(/logout=true/i);

  useEffect(() => {
    if (logoutMatches) {
      logoutClearance();
      logout({ logoutParams: { returnTo: window.location.origin } });
      return;
    }
  }, [logoutMatches, logout]);

  // 1. check if we are in callback page and we are redirect to emailSend page
  // 2. check if we are in public path
  const callBackToPublic =
    window.location.pathname.includes('/callback') && localStorage.getItem('redirectTo')?.includes('/emailSend');
  const isPublicPath = window.location.pathname.includes('/emailSend');
  const skipLogin = isPublicPath || callBackToPublic || errorMatches || errorInfoMatches;

  const orgClientCondition = isOrgLogin || (orgMatches && inviteMatches);

  const login = useCallback(
    (org_id?: string) => {
      if (skipLogin) {
        return;
      }
      const params: AuthorizationParams = {
        organization: org_id ?? idToken?.metadata?.last_login_org,
        redirect_uri: getCallbackURL(undefined, !!orgClientCondition),
      };
      if (inviteMatches && orgMatches) {
        params.invitation = inviteMatches[1];
        params.organization = orgMatches[1];
      }

      // custom login params, we need to pass the ext-origin to auth0 login with organization button
      params['ext-origin'] = window.location.origin;
      loginWithRedirect({
        authorizationParams: params,
      });
    },
    [idToken?.metadata?.last_login_org, inviteMatches, loginWithRedirect, orgMatches, orgClientCondition, skipLogin]
  );

  useEffect(() => {
    if (isLoading) {
      console.log('fetch session status...');
    }
  }, [isLoading]);

  const createOrgAndLogin = useCallback(async () => {
    const { data: response } = await axiosOrgService.post('/users/me/create_org', null);
    const jobId = response?.job_id;
    if (jobId) {
      console.log('auto create org job id:', jobId);
      const id = setInterval(async () => {
        const { data: response } = await axiosOrgService.get(`/jobs/${jobId}`);
        const { job_status, org_id, job_params } = response;
        if (job_status === 'SUCCESS') {
          clearInterval(id);
          login(org_id);
        } else if (job_status === 'FAILURE') {
          clearInterval(id);
          console.error(job_params.error_detail.message);
        }
      }, 2000);
    }
  }, [login]);

  // fetch id token and make sure we have org_id in session token (idToken)
  useEffect(() => {
    const fetchIdToken = async () => {
      const idToken = await getIdTokenClaims();
      if (!idToken?.email_verified) {
        logoutClearance();
        logout({
          logoutParams: {
            returnTo: getCallbackURL(`/emailSend?email=${encodeURI(idToken?.email || '')}`),
          },
        });
      } else if (!idToken?.org_id) {
        // no org id, we need to re login
        console.warn('no org_id found in id token');
        if (idToken?.metadata?.last_login_org) {
          console.warn('try to re login with last_login_org');
          login(idToken?.metadata?.last_login_org);
        } else {
          console.warn('try to get org id from /users/me/orgs api');
          axiosCluster.defaults.headers.common['Authorization'] = `Bearer ${idToken?.__raw}`;
          axiosOrgService.defaults.headers.common['Authorization'] = `Bearer ${idToken?.__raw}`;
          getAxiosInstance().defaults.headers.common['Authorization'] = `Bearer ${idToken?.__raw}`;

          const orgs = await axiosOrgService.get('/users/me/orgs');
          const org_id = orgs.data?.[0]?.org_id;
          if (org_id) {
            login(org_id);
          } else {
            console.warn('no org id found for user');
            createOrgAndLogin();
          }
        }
      }

      // only set idToken when we have org_id
      if (idToken && idToken.org_id) {
        setIdToken(idToken);
      }
    };

    if (isAuthenticated && !idToken) {
      fetchIdToken();
    }
  }, [isAuthenticated, idToken, setIdToken, getIdTokenClaims, login, logout, createOrgAndLogin]);

  // check if we have get the session status from auth0 and if not authenticate, we do log in
  useEffect(() => {
    if (logoutMatches) {
      return;
    }

    if (inviteMatches && orgMatches) {
      login(orgMatches[1]);
      return;
    }
    if (!isAuthenticated && !isLoading) {
      login();
    }
  }, [isAuthenticated, login, isLoading, inviteMatches, orgMatches, logoutMatches]);

  // error handling
  useEffect(() => {
    if (errorMatches) {
      console.error('Error:', errorMatches[1]);
      setErrorInfo(errorInfoMatches ? errorInfoMatches[1] : errorMatches[1]);
      return;
    }
  }, [errorMatches, errorInfoMatches]);

  // 1 sync id token to axios headers
  // 2 get org list and current org
  // 3 set current org and user info
  // 4.validate id token on server
  useLayoutEffect(() => {
    const getOrg = async (orgId: string) => {
      let response = await axiosController.get('v2/org/self');
      const orgs: MyOrgList = response.data.Result;
      setOrgList(orgs);
      const currentOrg = orgs.find((org) => org.org_id === orgId);
      if (!currentOrg) {
        console.warn('Can not found valid organization for user');
        if (orgs.length) {
          login(orgs[0].org_id!);
        } else {
          createOrgAndLogin();
        }
      } else {
        setCurrentOrg(currentOrg);
      }
    };

    const getUserInfo = async () => {
      try {
        const response = await axiosOrgService.get('/users/me');
        const userInfo: UserInfo = response.data;
        setUserInfo(userInfo);
      } catch (error) {
        // @ts-ignore
        setErrorInfo(getErrorMessage(error));
      }
    };

    const checkIDTokenOnServer = async () => {
      // we call a very simple api to check if the id token is valid on server
      try {
        await axiosController.get('v2/workspaces/meta');
        setIsTokenValidOnServer(true);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 401) {
            console.error('ID token is invalid on server, re-login');
            logout({ logoutParams: { returnTo: window.location.origin } });
          }
        }
      }
    };

    if (idToken) {
      axiosController.defaults.headers.common['Authorization'] = `Bearer ${idToken.__raw}`;
      axiosOrgService.defaults.headers.common['Authorization'] = `Bearer ${idToken.__raw}`;
      axiosCluster.defaults.headers.common['Authorization'] = `Bearer ${idToken.__raw}`;
      getAxiosInstance().defaults.headers.common['Authorization'] = `Bearer ${idToken.__raw}`;

      sessionStorage.setItem(ID_TOKEN_KEY, idToken.__raw);

      // Set idToken to cookie for tools header usage
      const localEnv = window.location.hostname.includes('localhost');
      const mainDomain = localEnv ? 'tgcloud-dev.com' : getMainDomain();
      const domain = localEnv ? '' : `domain=${mainDomain}`;
      document.cookie = `idTokenV4=${idToken.__raw};max-age=86400;path=/;${domain};secure`;

      if (idToken.org_id) {
        getOrg(idToken.org_id);
        getUserInfo();
        checkIDTokenOnServer();
      }
    } else {
      delete axiosCluster.defaults.headers.common['Authorization'];
      delete axiosController.defaults.headers.common['Authorization'];
      delete axiosOrgService.defaults.headers.common['Authorization'];
      delete getAxiosInstance().defaults.headers.common['Authorization'];
    }
  }, [idToken, setCurrentOrg, setOrgList, setUserInfo, setIsTokenValidOnServer, login, createOrgAndLogin, logout]);

  // 1. setup queryClient
  // 2. handle 401 error and re-login
  const [queryClient] = useState(() => {
    const cacheTime = 1000 * 60 * 60 * 24; // we change cache time from 5min to 1day

    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 0, // default value
          cacheTime: cacheTime,
          refetchOnWindowFocus: false,
          // we need this for long polling queries
          refetchIntervalInBackground: true,
          retry: false,
          onError: (error) => {
            if (error instanceof AxiosError) {
              const isControllerError =
                error.config?.baseURL === axiosController.defaults.baseURL ||
                error.config?.baseURL === axiosOrgService.defaults.baseURL;
              const isInstanceError = !isControllerError;

              const instanceLoginFailed = Number(localStorage.getItem('instanceLoginFailed') || '0');
              let needLogin = error.response?.status === 401;
              // check if is instance error and if we have failed login before, if so, we do not log in again.
              if (needLogin && isInstanceError) {
                // if (instanceLoginFailed >= 1) {
                needLogin = false;
                // }
              }
              if (needLogin) {
                // !!! DO NOT clear the queryClient, otherwise it will trigger refetch of all queries
                login();
                if (isInstanceError) {
                  localStorage.setItem('instanceLoginFailed', (instanceLoginFailed + 1).toString());
                }
              }
            }
          },
        },
      },
    });
    return queryClient;
  });

  // kill the loading spinner when react start to Render
  useLayoutEffect(() => {
    clearPageSpinner();
  }, []);

  if (errorMatches || errorInfoMatches) {
    return <NegativeNotification>{decodeURI(errorInfo)}</NegativeNotification>;
  }

  // 1. wait until we have valid idToken(with org_id)
  // 2. wait until we check the idToken on server
  // 3. wait until we have valid currentOrg
  // 4. wait until we have valid userInfo
  // 5. if in a publicPath, we do not show the spinner
  if ((!idToken || !isTokenValidOnServer || !currentOrg.org_id || !userInfo.id) && !skipLogin) {
    return (
      <div
        className={css({
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <Spinner $color={theme.colors.primary900} />
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <UserGuideProvider>
        <TutorialProvider>
          <RouterProvider router={generateRouter(userInfo)} />
        </TutorialProvider>
      </UserGuideProvider>
    </QueryClientProvider>
  );
}

function generateRouter(userInfo: UserInfo) {
  const isAdmin = isOrgAdmin(userInfo.roles);

  const showBilling = isBillingAdmin(userInfo.roles) || isBillingViewer(userInfo.roles);

  const adminRoutes = [];

  if (isAdmin) {
    adminRoutes.push({
      path: 'users',
      element: (
        <Suspense fallback={<LoadingIndicator />}>
          <Users />
        </Suspense>
      ),
    });
    adminRoutes.push({
      path: 'audit-log',
      element: (
        <Suspense fallback={<LoadingIndicator />}>
          <AuditLog />
        </Suspense>
      ),
    });
  }

  if (showBilling || isAdmin) {
    adminRoutes.push({
      path: 'bills',
      element: (
        <Suspense fallback={<LoadingIndicator />}>
          <Bill />
        </Suspense>
      ),
    });
  }

  if (isAdmin) {
    adminRoutes.push({
      element: <ProtectedRoutes />,
      path: 'settings',
      children: [
        {
          index: true,
          element: <Settings />,
        },
      ],
    });
  }

  const routes: RouteObject[] = [
    {
      path: '/',
      element: (
        <WorkspaceProvider>
          <HomeLayout />
        </WorkspaceProvider>
      ),
      errorElement: <Fallback />,
      children: [
        {
          index: true,
          element: (
            <ValidateCredit>
              <Home />
            </ValidateCredit>
          ),
          errorElement: <Fallback />,
        },
        {
          path: '*',
          element: <NoPermission />,
        },
        {
          path: 'terms',
          element: (
            <Suspense fallback={<LoadingIndicator />}>
              <LegalTerms />
            </Suspense>
          ),
        },
        {
          path: 'emailSend',
          element: (
            <Suspense fallback={<LoadingIndicator />}>
              <EmailSend />
            </Suspense>
          ),
        },
        {
          path: 'groups',
          element: (
            <ValidateCredit>
              <Groups />
            </ValidateCredit>
          ),
          errorElement: <Fallback />,
        },
        {
          path: 'groups/:groupID/*',
          element: (
            <ValidateCredit>
              <WorkGroup />
            </ValidateCredit>
          ),
          errorElement: <Fallback />,
        },
        {
          path: 'design-schema',
          element: (
            <Suspense fallback={<LoadingIndicator />}>
              <ValidateCredit>
                <Designer />
              </ValidateCredit>
            </Suspense>
          ),
          errorElement: <Fallback />,
        },
        {
          path: 'ingestion',
          element: (
            <Suspense fallback={<LoadingIndicator />}>
              <ValidateCredit>
                <Ingestion />
              </ValidateCredit>
            </Suspense>
          ),
          errorElement: <Fallback />,
        },
        {
          path: 'editor',
          element: (
            <Suspense fallback={<LoadingIndicator />}>
              <ValidateCredit>
                <Editor />
              </ValidateCredit>
            </Suspense>
          ),
          errorElement: <Fallback />,
        },
        {
          path: 'explore',
          element: (
            <Suspense fallback={<LoadingIndicator />}>
              <ValidateCredit>
                <Explore />
              </ValidateCredit>
            </Suspense>
          ),
          errorElement: <Fallback />,
        },
        {
          path: 'marketplace',
          children: [
            {
              path: 'solutions',
              element: (
                <Suspense fallback={<LoadingIndicator />}>
                  <ValidateCredit>
                    <Solution />
                  </ValidateCredit>
                </Suspense>
              ),
              errorElement: <Fallback />,
            },
            {
              path: 'addons',
              element: (
                <Suspense fallback={<LoadingIndicator />}>
                  <ValidateCredit>
                    <Addon />
                  </ValidateCredit>
                </Suspense>
              ),
              errorElement: <Fallback />,
            },
          ],
        },
        ...(isAdmin || showBilling
          ? [
              {
                path: 'admin',
                children: adminRoutes,
              },
            ]
          : []),
      ],
    },
    {
      path: '/groups/new',
      element: (
        <ValidateCredit>
          <WorkspaceProvider>
            <GroupLayout />
          </WorkspaceProvider>
        </ValidateCredit>
      ),
      errorElement: <Fallback />,
      children: [
        {
          index: true,
          element: <WorkGroupForm />,
        },
        {
          path: 'spaces/config',
          element: <WorkSpaceForm />,
        },
      ],
    },
    {
      path: '/groups/:groupID/spaces',
      element: (
        <ValidateCredit>
          <WorkspaceProvider>
            <SpaceLayout />
          </WorkspaceProvider>
        </ValidateCredit>
      ),
      errorElement: <Fallback />,
      children: [
        {
          path: 'config',
          element: <WorkSpaceForm />,
        },
      ],
    },
    {
      path: '/callback',
      element: <Callback />,
    },
    ...generateAdminRouter(),
  ];

  return createBrowserRouter(routes);
}

function generateAdminRouter() {
  const routes: RouteObject[] = [
    {
      path: '/dashboard',
      element: (
        <WorkspaceProvider isAdminDashboardPage={true}>
          <HomeLayout isAdminDashboardPage />
        </WorkspaceProvider>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="workspace" />,
        },
        {
          path: 'workspace',
          element: (
            <Suspense fallback={<LoadingIndicator />}>
              <AdminWorkspaces />
            </Suspense>
          ),
        },
        {
          path: 'org',
          element: (
            <Suspense fallback={<LoadingIndicator />}>
              <AdminOrg />
            </Suspense>
          ),
        },
      ],
    },
  ];

  return routes;
}
