import { forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Select } from '@tigergraph/app-ui-lib/select';
import { GLOBAL_GRAPH_NAME } from '@tigergraph/tools-models';
import { Option, SIZE, StyledDropdownContainer } from 'baseui/select';
import { GraphIcon } from '@/pages/home/icons';
import CreateNewGraphModal from './CreateNewGraphModal';
import DropGraphModal from './DropGraphModal';
import { expand } from 'inline-style-expand-shorthand';
import IconButton from '@/components/IconButton';
import { MdDeleteOutline } from 'react-icons/md';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { Button } from '@tigergraph/app-ui-lib/button';
import { StyleObject } from 'styletron-standard';
import ConfirmModal from '@/components/ConfirmModal';
import RefreshSchema from '@/components/graphSelect/RefreshSchema';

type GraphSelectProps = {
  fromSolution?: boolean;
  graph?: string;
  excludeGlobalGraph?: boolean;
  isLoading?: boolean;
  maxWidth?: string;
  maxHeight?: string;
  size?: (typeof SIZE)[keyof typeof SIZE];
  enableRefresh?: boolean;
  isSchemaChanged?: () => boolean;
  refetchSchema?: () => void;
};

export default function GraphSelect(props: GraphSelectProps) {
  const [css, theme] = useStyletron();
  const {
    fromSolution,
    graph,
    excludeGlobalGraph,
    isLoading,
    maxWidth,
    maxHeight,
    size,
    enableRefresh,
    isSchemaChanged,
    refetchSchema,
  } = props;
  const {
    graphNames,
    currentGraph: workspaceGraph,
    currentWorkspace,
    refetchSimpleAuth,
    setCurrentGraph,
  } = useWorkspaceContext();
  let currentGraph = workspaceGraph;
  if (fromSolution) {
    currentGraph = graph!;
  }

  const [isCreatingGraph, setIsCreatingGraph] = useState(false);
  const [isDeletingGraph, setIsDeletingGraph] = useState(false);
  const [deletingGraphName, setDeletingGraphName] = useState('');
  const [showSchemaChangeModal, setShowSchemaChangeModal] = useState<{
    open: boolean;
    graph: string;
    message: string;
  }>({
    open: false,
    graph: '',
    message: '',
  });

  // Refetch simple auth to get the latest graph names
  useEffect(() => {
    refetchSimpleAuth();
  }, [refetchSimpleAuth]);

  const curGraphNames = useMemo(() => {
    let names = [...graphNames];
    if (fromSolution) {
      names.push(graph!);
    }
    if (excludeGlobalGraph) {
      return names.filter((name) => name !== GLOBAL_GRAPH_NAME);
    } else {
      return names;
    }
  }, [excludeGlobalGraph, fromSolution, graph, graphNames]);

  useEffect(() => {
    if (curGraphNames.length > 0 && (!currentGraph || (excludeGlobalGraph && currentGraph === GLOBAL_GRAPH_NAME))) {
      setCurrentGraph(curGraphNames[0]);
    }
  }, [curGraphNames, currentGraph, excludeGlobalGraph, setCurrentGraph]);

  const getValueLabel = ({ option, isValue }: { option?: Option; isValue?: boolean }) => {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          justifyContent: isValue ? 'flex-start' : 'space-between',
        })}
      >
        {isValue && (
          <div
            className={css({
              minWidth: '16px',
            })}
          >
            <GraphIcon />
          </div>
        )}
        <span
          className={css({
            maxWidth: maxWidth ? maxWidth : '100%',
            minWidth: '26px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          })}
          title={option!.label + ''}
        >
          {option!.label}
        </span>
        {currentWorkspace?.is_rw && !isValue && option!.id !== GLOBAL_GRAPH_NAME ? (
          <IconButton
            onClick={() => {
              setDeletingGraphName(String(option!.id));
              setIsDeletingGraph(true);
            }}
          >
            <MdDeleteOutline size={16} color={theme.colors.negative} />
          </IconButton>
        ) : (
          // The visibility style of the last item of the global graph
          <div />
        )}
      </div>
    );
  };

  return (
    <div>
      <div
        className={css({
          display: 'flex',
          columnGap: '8px',
        })}
      >
        <Select
          size={size}
          disabled={fromSolution}
          isLoading={isLoading}
          options={
            fromSolution
              ? [{ label: graph, id: graph }]
              : curGraphNames.map((name) => ({
                  label: name === GLOBAL_GRAPH_NAME ? 'Global' : name,
                  id: name,
                }))
          }
          value={
            !curGraphNames.includes(currentGraph)
              ? []
              : [
                  {
                    label: currentGraph === GLOBAL_GRAPH_NAME ? 'Global' : currentGraph,
                    id: currentGraph,
                  },
                ]
          }
          getValueLabel={({ option }) => getValueLabel({ option, isValue: true })}
          getOptionLabel={getValueLabel}
          onChange={({ value }) => {
            if (isDeletingGraph || value.length === 0) {
              return;
            }
            if (isSchemaChanged?.()) {
              setShowSchemaChangeModal({
                open: true,
                graph: value[0].id?.toString() ?? '',
                message: 'You have unsaved changes that will be lost.\n\nDo you still want to switch the graph?',
              });
            } else {
              setCurrentGraph(value[0].id?.toString());
            }
          }}
          placeholder="Select or create a graph"
          clearable={false}
          searchable={false}
          overrides={{
            ValueContainer: {
              style: {
                height: size === SIZE.mini ? '22px' : '32px',
              },
            },
            Dropdown: {
              style: {
                width: '100%',
                maxHeight: maxHeight ?? '300px',
                boxShadow: 'none',
                borderBottom: 'none',
                marginBottom: '0',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
              },
            },
            DropdownContainer: {
              component: forwardRef((p: { children: ReactNode[]; style: StyleObject }, ref) => {
                return (
                  <StyledDropdownContainer
                    {...p}
                    ref={ref}
                    style={{
                      ...p.style,
                      boxShadow: 'rgba(0, 0, 0, 0.1) -2px -2px 6px, rgba(0, 0, 0, 0.1) 2px 2px 6px',
                    }}
                  >
                    {p.children}
                    {currentWorkspace?.is_rw && (
                      <Button
                        kind="secondary"
                        size={SIZE.compact}
                        onClick={() => {
                          if (isSchemaChanged?.()) {
                            setShowSchemaChangeModal({
                              open: true,
                              graph: '',
                              message:
                                'You have unsaved changes that will be lost.\n\nDo you still want to create a new graph?',
                            });
                          } else {
                            setIsCreatingGraph(true);
                          }
                        }}
                        overrides={{
                          BaseButton: {
                            style: {
                              width: '100%',
                              ...expand({
                                padding: '8px',
                                border: `1px solid ${theme.colors.divider}`,
                                fontWeight: 500,
                              }),
                              color: theme.colors['text.link'],
                              backgroundColor: theme.colors['background.primary'],
                            },
                          },
                        }}
                      >
                        <span
                          className={css({
                            width: '100%',
                            textAlign: 'left',
                            fontSize: size === SIZE.mini ? '12px' : '14px',
                          })}
                        >
                          Create New Graph
                        </span>
                      </Button>
                    )}
                  </StyledDropdownContainer>
                );
              }),
            },
            ControlContainer: {
              style: {
                ...expand({
                  minWidth: '120px',
                  borderRadius: '2px',
                }),
              },
            },
            DropdownListItem: {
              style: {
                ...expand({
                  padding: '0',
                }),
              },
            },
            OptionContent: {
              style: {
                ':last-child > :last-child > :last-child': {
                  visibility: 'hidden',
                },
                ':hover > :last-child > :last-child > :last-child': {
                  visibility: 'visible',
                },
                ...expand({
                  padding: size === SIZE.mini ? '4px 8px' : '8px',
                }),
              },
            },
            IconsContainer: {
              props: {
                // E2E test locator
                'aria-label': 'graph-select',
              },
            },
            DropdownOption: {
              props: {
                style: {
                  fontSize: size === SIZE.mini ? '12px' : '14px',
                },
              },
            },
          }}
        />
        {enableRefresh && <RefreshSchema refetchSchema={refetchSchema} />}
      </div>
      <CreateNewGraphModal isOpen={isCreatingGraph} onClose={() => setIsCreatingGraph(false)} />
      {currentWorkspace && (
        <DropGraphModal
          isOpen={isDeletingGraph}
          workspace={currentWorkspace}
          graphName={deletingGraphName}
          onClose={() => {
            setIsDeletingGraph(false);
            setDeletingGraphName('');
          }}
          refetchSchema={refetchSchema}
        />
      )}
      <ConfirmModal
        open={showSchemaChangeModal.open}
        header="Warning"
        body={showSchemaChangeModal.message}
        onConfirm={() => {
          if (showSchemaChangeModal.graph) {
            setCurrentGraph(showSchemaChangeModal.graph);
          } else {
            setIsCreatingGraph(true);
          }
          setShowSchemaChangeModal({ open: false, graph: '', message: '' });
        }}
        onCancel={() => setShowSchemaChangeModal({ open: false, graph: '', message: '' })}
      />
    </div>
  );
}
