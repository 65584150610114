import { Completion, CompletionContext, CompletionSource } from '@codemirror/autocomplete';
import { EditorState, Text } from '@codemirror/state';
import { syntaxTree } from '@codemirror/language';
import { SyntaxNode } from '@lezer/common';
import {
  baseTypes,
  accums,
  graphFuncs,
  globalFuncs,
  jsonObjFuncs,
  builtInAttrs,
  StmtNames,
  stmtKeywordsMap,
  jsonArrFuncs,
  operators,
  containerFuncs,
  fileFuncs,
} from './queryKw';
import { schemaCache } from '@/pages/editor/GSQL/schemaCache';
import { kws as scriptKws } from '@/pages/editor/GSQL/scriptKw';
import { isContainerType } from '@/pages/editor/GSQL/util';

function tokenBefore(tree: SyntaxNode) {
  const cursor = tree.cursor().moveTo(tree.from, -1);
  while (/Comment/.test(cursor.name)) cursor.moveTo(cursor.from, -1);
  return cursor.node;
}

function idName(doc: Text, node: SyntaxNode): string {
  const text = doc.sliceString(node.from, node.to);
  const quoted = /^([`'"])(.*)\1$/.exec(text);
  return quoted ? quoted[2] : text;
}

function plainID(node: SyntaxNode | null) {
  return node && node.name == 'Identifier';
}

function parentsFor(doc: Text, node: SyntaxNode | null) {
  for (let path = []; ; ) {
    if (!node || node.name != '.') return path;
    const name = tokenBefore(node);
    if (!plainID(name)) return path;
    path.unshift(idName(doc, name));
    node = tokenBefore(name);
  }
}

export function findParent(at: SyntaxNode, names: string[]) {
  for (let parent = at.parent; parent; parent = parent.parent) {
    if (names.includes(parent.name)) return parent;
  }
  return null;
}

interface SourceContext {
  from: number;
  parents: string[];
  aliases: Record<string, AliasType> | null;
  graphName: string;
}
function sourceContext(state: EditorState, startPos: number, at: SyntaxNode): SourceContext {
  let parents: string[] = [];
  let from = at.from;
  if (at.name === '.') {
    parents = parentsFor(state.doc, at);
    from = startPos;
  } else {
    parents = parentsFor(state.doc, tokenBefore(at));
  }

  const graphName = getQueryGraphName(state.doc, at);
  const aliases = getAliases(state.doc, at, getVertexSetTypes(state.doc, at, getParamVertexTypes(state.doc, at)));

  return { from, parents, aliases, graphName };
}

export function getQueryGraphName(doc: Text, at: SyntaxNode) {
  let statement: SyntaxNode | null = null;
  for (let parent: SyntaxNode | null = at; !statement; parent = parent.parent) {
    if (!parent) return '';
    if (parent.name == 'CreateQuery') statement = parent;
  }

  const graphNode = statement.getChild('GraphOption')?.getChild('Identifier');
  if (graphNode) {
    return doc.sliceString(graphNode.from, graphNode.to);
  }

  // search upwards for a QueryBlock
  let queryBlock: SyntaxNode | null = statement;
  for (; queryBlock; queryBlock = queryBlock.parent) {
    if (queryBlock.name === 'QueryBlock') break;
  }
  if (!queryBlock) return '';

  // search prev siblings for a UseGraphStmt
  for (statement = queryBlock; statement; statement = statement.prevSibling) {
    if (statement.name === 'UseGraphStmt') {
      const graphNode = statement.getChild('Identifier');
      return graphNode ? doc.sliceString(graphNode.from, graphNode.to) : '';
    }
  }

  return '';
}

function noPrevSibling(node: SyntaxNode) {
  // no prev sibling or prev siblings are all comments
  for (let prev = node.prevSibling; prev; prev = prev.prevSibling) {
    if (prev.name !== 'LineComment' && prev.name !== 'BlockComment') return false;
  }
  return true;
}

function prevSibling(node: SyntaxNode) {
  for (let prev = node.prevSibling; prev; prev = prev.prevSibling) {
    if (prev.name !== 'LineComment' && prev.name !== 'BlockComment') return prev;
  }
  return null;
}

interface IdentInfo {
  tuple: string[];
  variable: Map<string, string>;
  globalAccum: string[];
  localAccum: string[];
  vertexSet: string[];
}

function getIdentNames(doc: Text, at: SyntaxNode): IdentInfo {
  const result: IdentInfo = {
    tuple: [],
    variable: new Map(),
    globalAccum: [],
    localAccum: [],
    vertexSet: [],
  };

  for (let stmt: SyntaxNode | null = at; stmt; stmt = stmt.parent) {
    // if find QueryBodyStmts, get ident names from it
    if (stmt.name === 'QueryBodyStmts') {
      getIdentNamesFromStmts(doc, stmt, result);
    }

    // if find ForEachStmt, get ident names from it
    if (stmt.name === 'ForEachStmt') {
      const control = stmt.getChild('ForEachControl');
      if (!control) continue;

      // Handle single identifier or multiple identifiers in parentheses
      const firstChild = control.firstChild;
      if (firstChild?.name === 'Identifier') {
        result.variable.set(doc.sliceString(firstChild.from, firstChild.to), 'UNKNOWN');
      } else if (firstChild?.name === '(') {
        // Handle multiple identifiers
        for (let node = firstChild.nextSibling; node && node.name !== ')'; node = node.nextSibling) {
          if (node.name === 'Identifier') {
            result.variable.set(doc.sliceString(node.from, node.to), 'UNKNOWN');
          }
        }
      }
    }

    if (stmt.name === 'CreateQuery') {
      const parameterList = stmt.getChild('ParameterList');
      if (parameterList) {
        for (const param of parameterList.getChildren('Parameter')) {
          const paramTypeNode = param.getChild('ParameterType');
          const paramName = paramTypeNode?.nextSibling;
          if (paramName?.name === 'Identifier') {
            const varName = doc.sliceString(paramName.from, paramName.to);
            result.variable.set(varName, doc.sliceString(paramTypeNode!.from, paramTypeNode!.to));
          }
        }
      }
    }
  }

  result.variable.delete(doc.sliceString(at.from, at.to));
  return result;
}

function getIdentNamesFromStmts(doc: Text, queryBodyStmts: SyntaxNode, result: IdentInfo) {
  for (const stmt of queryBodyStmts.getChildren('DeclStmt')) {
    const childStmt = stmt.firstChild!;
    if (childStmt.name === 'BaseDeclStmt') {
      const baseTypeNode = childStmt.getChild('BaseType')!;
      const baseType = doc.sliceString(baseTypeNode.from, baseTypeNode.to);
      for (let node: SyntaxNode | null = baseTypeNode; node; node = node.nextSibling) {
        if (node.name === 'BaseType' || node.name === ',') {
          if (node.nextSibling?.name === 'Identifier') {
            const varName = doc.sliceString(node.nextSibling.from, node.nextSibling.to);
            result.variable.set(varName, baseType);
            node = node.nextSibling;
          }
        }
      }
    }

    if (childStmt.name === 'FileDeclStmt') {
      const fileNode = childStmt.getChild('Identifier');
      if (fileNode) {
        const fileVarName = doc.sliceString(fileNode.from, fileNode.to);
        result.variable.set(fileVarName, 'FILE');
      }
    }
  }

  // AssignmentStmt
  for (const assignment of queryBodyStmts.getChildren('AssignmentStmt')) {
    const childStmt = assignment.firstChild!;
    if (childStmt.name === 'Identifier') {
      result.variable.set(doc.sliceString(childStmt.from, childStmt.to), 'UNKNOWN');
    }
  }

  for (const selectStmt of queryBodyStmts.getChildren('SelectStmt')) {
    const childStmt = selectStmt.firstChild!;
    if (childStmt.name === 'GSQLSelectClause' && childStmt.firstChild?.name === 'Identifier') {
      result.vertexSet.push(doc.sliceString(childStmt.firstChild.from, childStmt.firstChild.to));
    }
  }

  for (const accumDeclStmt of queryBodyStmts.getChildren('DeclStmt')) {
    const childStmt = accumDeclStmt.firstChild!;
    if (childStmt.name !== 'AccumDeclStmt') continue;

    const gAccumIdent = childStmt.getChildren('GlobalAccumIdent') || [];
    const lAccumIdent = childStmt.getChildren('LocalAccumIdent') || [];
    result.globalAccum.push(...gAccumIdent.map((node) => doc.sliceString(node.from, node.to)));
    result.localAccum.push(...lAccumIdent.map((node) => doc.sliceString(node.from, node.to)));
  }

  // TypedefStmt { TYPEDEF TUPLE "<" commaSep1<BaseType Identifier> ">" TypedefName }
  for (const typedef of queryBodyStmts.getChildren('TypedefStmt')) {
    const tupleNode = typedef.getChild('TupleType');
    if (tupleNode) {
      result.tuple.push(doc.sliceString(tupleNode.from, tupleNode.to));
    }
  }

  for (const vSetVarDeclStmt of queryBodyStmts.getChildren('VSetVarDeclStmt')) {
    const childStmt = vSetVarDeclStmt.firstChild!;
    if (childStmt.name === 'Identifier') {
      result.vertexSet.push(doc.sliceString(childStmt.from, childStmt.to));
    }
  }

  return result;
}

function getParamVertexTypes(doc: Text, at: SyntaxNode): Record<string, string> {
  let statement;
  for (let parent: SyntaxNode | null = at; !statement; parent = parent.parent) {
    if (!parent) return {};
    if (parent.name == 'CreateQuery') statement = parent;
  }
  const paramToVertexType: Record<string, string> = {};
  const paramList = statement.getChild('ParameterList');
  if (!paramList) return {};
  for (const param of paramList.getChildren('Parameter')) {
    const paramName = param.getChild('Identifier');
    const baseType = param.getChild('BaseType');
    if (paramName && baseType && baseType.firstChild?.name === 'VERTEX' && baseType.lastChild?.name === 'Identifier') {
      const vertexType = doc.sliceString(baseType.lastChild.from, baseType.lastChild.to);
      paramToVertexType[doc.sliceString(paramName.from, paramName.to)] = vertexType;
    }
  }
  return paramToVertexType;
}

type VertexSetType = string[] | 'UNKNOWN' | 'ANY';
type AliasType = string[] | 'UNKNOWN' | 'ANY';
function getVertexSetTypes(
  doc: Text,
  at: SyntaxNode,
  paramToVertexType: Record<string, string>
): Record<string, VertexSetType> {
  let statement;
  for (let parent: SyntaxNode | null = at; !statement; parent = parent.parent) {
    if (!parent) return {};
    if (parent.name == 'CreateQuery') statement = parent;
  }
  const vSetTypeMap: Record<string, VertexSetType> = {};
  const declStmts = statement.getChild('QueryBodyStmts')?.getChildren('VSetVarDeclStmt') || [];
  for (const declStmt of declStmts) {
    const varNode = declStmt.getChild('Identifier');
    if (!varNode) continue;

    const varName = doc.sliceString(varNode.from, varNode.to);
    let vSetTypes: VertexSetType = [];
    const seeds = declStmt.getChild('Seeds');
    for (let seed = seeds?.firstChild; seed; seed = seed.nextSibling) {
      if (seed.name === 'VertexSet') {
        const seedText = doc.sliceString(seed.from, seed.to);
        if (seedText.endsWith('.*')) {
          // VertexType.*
          vSetTypes.push(seedText.slice(0, -2));
        } else {
          // paramName
          const paramVertexType = paramToVertexType[seedText];
          if (paramVertexType) {
            vSetTypes.push(paramVertexType);
          } else {
            vSetTypes = 'UNKNOWN';
            break;
          }
        }
      } else if (seed.name === 'ANY' || seed.name === '_') {
        vSetTypes = 'ANY';
        break;
      }
    }
    vSetTypeMap[varName] = vSetTypes;
  }

  return vSetTypeMap;
}

function getAliases(
  doc: Text,
  at: SyntaxNode,
  vSetTypeMap: Record<string, VertexSetType>
): Record<string, AliasType> | null {
  let statement;
  for (let parent: SyntaxNode | null = at; !statement; parent = parent.parent) {
    if (!parent) return null;
    if (parent.name == 'SelectStmt' || parent.name == 'DeleteStmt' || parent.name == 'UpdateStmt') statement = parent;
  }
  const aliases: Record<string, AliasType> = {};

  const fromClause = statement.getChild('FromClause');
  if (!fromClause || fromClause.firstChild?.name !== 'FROM') return null;

  const fromClauseStr = doc
    .sliceString(fromClause.from, fromClause.to)
    .replace(/FROM/i, '')
    .replace(/(\/\/.*)|(\/\*[\s\S]*?\*\/)/g, '')
    .replace(/[\s()><]+/g, '');

  const fromClauseParts = fromClauseStr.split('-');
  for (const part of fromClauseParts) {
    const [vSetName, alias] = part.split(':');
    if (alias) {
      if (vSetName in vSetTypeMap) {
        aliases[alias] = vSetTypeMap[vSetName];
      } else if (['ANY', '_', ''].includes(vSetName.toUpperCase())) {
        aliases[alias] = 'ANY';
      } else {
        aliases[alias] = vSetName.split('|');
      }
    }
  }
  return aliases;
}

function inVertexEdgeTypeCtx(at: SyntaxNode) {
  let parent: SyntaxNode | null;
  return (
    (findParent(at, ['BaseType']) && at.prevSibling?.prevSibling?.name === 'VERTEX') ||
    findParent(at, ['Seeds']) ||
    ((parent = findParent(at, ['FromClause'])) && parent.firstChild?.name === 'FROM') ||
    ((parent = findParent(at, ['InsertStmt'])) && parent.firstChild?.name === 'INSERT')
  );
}

function wrapSchemaOptions(from: number, options: Completion[] = []) {
  return {
    from,
    to: undefined,
    options,
    validFor: /^\w*$/,
  };
}

export function completeFromSchema(): CompletionSource {
  return async (context: CompletionContext) => {
    const at = syntaxTree(context.state).resolveInner(context.pos, -1);
    if (!['Identifier', '.'].includes(at?.name)) return null;

    // completion from graph names
    if (['GraphOption', 'UseGraphStmt'].includes(at.parent?.name || '') && at.prevSibling?.name === 'GRAPH') {
      const graphNames = schemaCache.getGraphNames();
      if (!graphNames.length) return null;

      return {
        from: at.from,
        to: undefined,
        options: graphNames.map((graphName) => ({ label: graphName, type: 'graph' })),
        validFor: /^\w*$/,
      };
    }

    const { parents, from, aliases, graphName } = sourceContext(context.state, context.pos, at);
    const schemaCompletion = await schemaCache.getSchemaCompletion(graphName);
    if (!schemaCompletion) return null;

    // completion from vertex/edge types
    let options: Completion[] = [];
    if (inVertexEdgeTypeCtx(at)) {
      options.push(...(schemaCompletion?.getVertexEdgeCompletions() || []));
      return wrapSchemaOptions(from, options);
    }

    // completion from vertex/edge attrs
    //   1. is a alias
    if (parents.length == 1 && !findParent(at, ['VSetVarDeclStmt'])) {
      const alias = aliases && aliases[parents[0]];
      if (alias) {
        options.push(...graphFuncs.map((v) => ({ label: v, type: 'function' })));
        options.push(...builtInAttrs.map((v) => ({ label: v, type: 'field' })));

        if (alias === 'ANY' || alias === 'UNKNOWN') {
          options.push(...(schemaCompletion?.getAllAttrCompletions() || []));
        } else if (Array.isArray(alias)) {
          options.push(...(schemaCompletion?.getAttrCompletions(alias) || []));
        }
      } else {
        const { vertexSet } = getIdentNames(context.state.doc, at);
        if (vertexSet.includes(parents[0])) {
          // we didn't do type inference for vertex set, so we just show all attrs
          options.push(...(schemaCompletion?.getAllAttrCompletions() || []));

          options.push(...graphFuncs.map((v) => ({ label: v, type: 'function' })));
          options.push(...builtInAttrs.map((v) => ({ label: v, type: 'field' })));
        }
      }
    }

    return wrapSchemaOptions(from, options);
  };
}

export function completeFromIdents(): CompletionSource {
  return async (context: CompletionContext) => {
    const at = syntaxTree(context.state).resolveInner(context.pos, -1);
    if (
      !['Identifier', 'GlobalAccumIdent', 'LocalAccumIdent', '.', '@'].includes(at?.name) &&
      context.state.doc.sliceString(at.from, at.from + 1) !== '@'
    ) {
      return null;
    }

    if (!findParent(at, ['QueryBodyStmts', 'ParameterList'])) {
      return null;
    }

    const { parents, from, aliases } = sourceContext(context.state, context.pos, at);

    let {
      variable: vars,
      globalAccum: gAccums,
      localAccum: lAccums,
      tuple,
      vertexSet,
    } = getIdentNames(context.state.doc, at);

    // @ts-ignore
    if (window.printVars) {
      console.log('vars', vars);
      console.log('gAccums', gAccums);
      console.log('lAccums', lAccums);
      console.log('tuple', tuple);
      console.log('vertexSet', vertexSet);
    }

    let options: Completion[] = [];
    if (!parents.length) {
      if (isInTypeCtx(at) || isInParamCtx(at)) {
        options.push(...tuple.map((type) => ({ label: type, type: 'type' })));

        if (isInParamCtx(at)) {
          options.push(...['to_datetime'].map((func) => ({ label: func, type: 'function' })));
        }
      } else {
        options.push(...Array.from(vars.keys()).map((v) => ({ label: v, type: 'variable' })));
        options.push(...gAccums.map((v) => ({ label: v, type: 'variable' })));
        options.push(...vertexSet.map((v) => ({ label: v, type: 'variable' })));

        if (!inVertexEdgeTypeCtx(at)) {
          options.push(...Object.keys(aliases || {}).map((v) => ({ label: v, type: 'variable' })));
          options.push(...globalFuncs.map((v) => ({ label: v, type: 'function' })));
        }
      }
    } else if (parents.length == 1 && !findParent(at, ['VSetVarDeclStmt'])) {
      const parent = parents[0];
      const parentIsAlias = aliases && aliases[parent];
      if (parentIsAlias || vertexSet.includes(parent)) {
        options.push(...lAccums.map((v) => ({ label: v, type: 'field' })));
      } else if (vars.has(parent)) {
        const varType = vars.get(parent)!;
        if (varType === 'FILE') {
          options.push(...fileFuncs.map((v) => ({ label: v, type: 'function' })));
        } else if (varType === 'JSONOBJECT') {
          options.push(...jsonObjFuncs.map((v) => ({ label: v, type: 'function' })));
        } else if (varType === 'JSONARRAY') {
          options.push(...jsonArrFuncs.map((v) => ({ label: v, type: 'function' })));
        } else if (isContainerType(varType)) {
          options.push(...containerFuncs.map((v) => ({ label: v, type: 'function' })));
        }
      }
    }

    return {
      from,
      to: undefined,
      options,
      validFor: /^@{0,2}\w*$/,
    };
  };
}

function wrapKwOptions(from: number, kws: string[], options: Completion[] = []) {
  return {
    from,
    to: undefined,
    options: kws
      .map((keyword) => ({ label: keyword, type: keyword.includes(' ') ? 'multiwords' : 'keyword' } as Completion))
      .concat(options),
    validFor: /^[\w\-]*$/,
  };
}

function findInnerMostStmt(pos: SyntaxNode) {
  let parent: SyntaxNode | null = pos;
  while (parent && !StmtNames.includes(parent.name)) {
    parent = parent.parent;
  }
  return parent;
}

function isBeginOfStmt(pos: SyntaxNode | null) {
  // search upwards we can find a stmt
  // and in this process, all nodes don't have prevSibling
  while (pos) {
    if (pos.prevSibling) return false;
    if (pos.parent?.name.toLowerCase().includes('stmt')) {
      return true;
    }
    pos = pos.parent;
  }
  return false;
}

export function completeFromKeywords(): CompletionSource {
  // return ifNotIn(['String', 'LineComment', 'BlockComment', '.'], completeFromList(options));
  return (context: CompletionContext) => {
    const pos: SyntaxNode | null = syntaxTree(context.state).resolveInner(context.pos, -1);
    if (!pos || tokenBefore(pos).name === '.' || ['String', 'LineComment', 'BlockComment', '.'].includes(pos.name))
      return null;

    let keywords: string[] = ['FOR', 'GRAPH'];
    // if outside query body
    const outsideQueryBody = !findParent(pos, ['GSQLQuery', 'ParameterList']);
    if (outsideQueryBody) {
      keywords = scriptKws;
      const snippets = [
        'CREATE OR REPLACE',
        'CREATE QUERY',
        'INTERPRET QUERY',
        'FOR GRAPH',
        'USE GRAPH',
        'USE GLOBAL',
        'RUN QUERY',
        'INSTALL QUERY',
      ];
      return wrapKwOptions(
        pos.from,
        keywords,
        snippets.map((snippet) => ({ label: snippet, type: 'multiwords' }))
      );
    }

    // GSQLSelectClause
    if (pos.parent?.parent?.name === 'AssignmentStmt' && pos.parent?.prevSibling?.name === 'AssignOp') {
      keywords = ['SELECT', 'TRUE', 'FALSE'];
      return wrapKwOptions(pos.from, keywords);
    }

    // 'ANY' in set seeds
    if (pos.parent?.name === 'Seeds' || pos.parent?.parent?.name === 'Seeds') {
      keywords = ['ANY'];
      return wrapKwOptions(pos.from, keywords);
    }

    // keywords can start a statement or sub-statement
    if (isBeginOfStmt(pos)) {
      keywords = keywords.concat([
        'IF',
        'WHILE',
        'FOREACH',
        'CASE',
        'TRY',
        'RAISE',
        'PRINT',
        'LOG',
        'RETURN',
        'SELECT',
        'INSERT INTO',
        'UPDATE',
        'DELETE',
        'TYPEDEF',
        'CONTINUE',
        'BREAK',
        'ACCUM',
        'POST-ACCUM',
        'SAMPLE',
        'HAVING',
        'ORDER',
        'LIMIT',
      ]);
    }

    const innerMostStmt = findInnerMostStmt(pos);
    if (innerMostStmt && innerMostStmt.name in stmtKeywordsMap) {
      keywords = keywords.concat(stmtKeywordsMap[innerMostStmt.name]);
    }

    if (findParent(pos, ['Expression', 'Constant', 'Parameter'])) {
      keywords = keywords.concat(['TRUE', 'FALSE', 'GSQL_INT_MIN', 'GSQL_INT_MAX', 'GSQL_UINT_MAX']);
    }

    if (findParent(pos, ['Expression']) || pos?.parent?.prevSibling?.name === 'Expression') {
      keywords = keywords.concat(operators);
    }

    return wrapKwOptions(pos.from, keywords);
  };
}

export function completeFromAccumTypes(): CompletionSource {
  return (context: CompletionContext) => {
    const pos: SyntaxNode | null = syntaxTree(context.state).resolveInner(context.pos, -1);
    // only when cursor is at the beginning of a statement
    if (!pos || !noPrevSibling(pos)) return null;

    let options: Completion[] = [];
    // the proper context is: 1. in the begin of a statement; 2. in the query block
    const inAccumCtx = isBeginOfStmt(pos) || findParent(pos, ['AccumType']);
    if (
      inAccumCtx ||
      (['SET', 'BAG', 'MIN', 'MAX', 'SUM', 'AVG', 'MAP', 'LIST'].includes(pos.name) &&
        pos.parent?.name !== 'ParameterType')
    ) {
      options = accums.map((accum) => ({ label: accum, type: 'type' }));
    }

    return {
      from: pos.from,
      to: undefined,
      options,
      validFor: /^\w*$/,
    };
  };
}

function isInTypeCtx(pos: SyntaxNode) {
  const inTupleTypeCtx = pos.parent?.prevSibling?.name === 'Tuple';
  return isInParamCtx(pos) || inTupleTypeCtx || findParent(pos, ['BaseType', 'AccumType', 'Tuple', 'TupleType']);
}

function isInParamCtx(pos: SyntaxNode) {
  return (
    (pos.parent?.parent?.name === 'CreateQuery' && pos.parent?.prevSibling?.name === '(') ||
    findParent(pos, ['ParameterList'])
  );
}

export function completeFromBaseTypes(): CompletionSource {
  return (context: CompletionContext) => {
    const pos: SyntaxNode | null = syntaxTree(context.state).resolveInner(context.pos, -1);
    if (!pos || pos.prevSibling?.prevSibling?.name === 'VERTEX') return null;

    let options: Completion[] = [];
    if (pos.name === 'IN' && !['ForEachStmt', 'DmlSubForEachStmt'].includes(pos.parent?.name || '')) {
      options.push({ label: 'INT', type: 'type' });
    }

    if (isInTypeCtx(pos) || isBeginOfStmt(pos)) {
      options.push(...baseTypes.map((type) => ({ label: type, type: 'type' })));
    }

    return {
      from: pos.from,
      to: undefined,
      options,
      validFor: /^\w*$/,
    };
  };
}
