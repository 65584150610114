// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  whitespace = 259,
  LineComment = 1,
  BlockComment = 2,
  String = 3,
  Number = 4,
  ParenL = 5,
  ParenR = 6,
  BraceL = 7,
  BraceR = 8,
  BracketL = 9,
  BracketR = 10,
  Dot = 11,
  Identifier = 12,
  GRAPH = 13,
  PRINT = 14,
  LOG = 15,
  RETURNS = 16,
  EXCEPTION = 17,
  SELECT = 18,
  FROM = 19,
  WHERE = 20,
  SAMPLE = 21,
  WHEN = 22,
  ACCUM = 23,
  HAVING = 25,
  ASC = 26,
  DESC = 27,
  LIMIT = 28,
  DELETE = 29,
  INSERT = 30,
  UPDATE = 31,
  RANGE = 32,
  TYPEDEF = 33,
  TUPLE = 34,
  ANY = 35,
  API = 36,
  AS = 37,
  BY = 38,
  DISTINCT = 39,
  ISEMPTY = 40,
  LOADACCUM = 41,
  PER = 42,
  PINNED = 43,
  POST_ACCUM = 44,
  TARGET = 45,
  FOR = 46,
  GROUP = 47,
  MAP = 48,
  ORDER = 49,
  SYNTAX = 50,
  CURRENT_DATE = 51,
  CURRENT_TIME = 52,
  CURRENT_TIMESTAMP = 53,
  FILTER = 54,
  PRIMARY_ID = 55,
  VALUES = 56,
  INTO = 57,
  TO = 58,
  OFFSET = 59,
  TO_CSV = 60,
  STEP = 61,
  COALESCE = 62,
  AND = 63,
  OR = 64,
  NOT = 65,
  LIKE = 66,
  BETWEEN = 67,
  IS = 68,
  IN = 69,
  INTERSECT = 70,
  MINUS = 71,
  UNION = 72,
  ESCAPE = 73,
  GSQL_UINT_MAX = 74,
  GSQL_INT_MAX = 75,
  GSQL_INT_MIN = 76,
  TO_DATETIME = 77,
  TRUE = 78,
  FALSE = 79,
  NULL = 80,
  IF = 81,
  THEN = 82,
  ELSE = 83,
  WHILE = 84,
  DO = 85,
  FOREACH = 86,
  END = 87,
  CASE = 88,
  CONTINUE = 89,
  BREAK = 90,
  TRY = 91,
  RAISE = 92,
  RETURN = 93,
  INT = 94,
  UINT = 95,
  FLOAT = 96,
  DOUBLE = 97,
  STRING = 98,
  BOOL = 99,
  VERTEX = 100,
  EDGE = 101,
  JSONOBJECT = 102,
  JSONARRAY = 103,
  SET = 104,
  BAG = 105,
  FILE = 106,
  DATETIME = 107,
  SumAccum = 108,
  MaxAccum = 109,
  MinAccum = 110,
  AvgAccum = 111,
  OrAccum = 112,
  AndAccum = 113,
  BitwiseOrAccum = 114,
  BitwiseAndAccum = 115,
  ListAccum = 116,
  SetAccum = 117,
  BagAccum = 118,
  MapAccum = 119,
  HeapAccum = 120,
  GroupByAccum = 121,
  ArrayAccum = 122,
  COUNT = 123,
  MAX = 124,
  MIN = 125,
  AVG = 126,
  SUM = 127,
  GSQLQuery = 128,
  Statement = 129,
  CreateQuery = 130,
  ParameterList = 131,
  Parameter = 132,
  ParameterType = 133,
  BaseType = 134,
  Constant = 138,
  Bool = 139,
  GraphOption = 141,
  ReturnsOption = 142,
  APIOption = 143,
  SyntaxOption = 144,
  QueryBodyStmts = 145,
  TypedefStmt = 146,
  Tuple = 147,
  TupleType = 148,
  VSetVarDeclStmt = 149,
  Seeds = 150,
  VertexSet = 151,
  AssignmentStmt = 153,
  Expression = 154,
  GlobalAccumIdent = 155,
  LocalAccumIdent = 156,
  MemberExpression = 157,
  PropertyName = 158,
  CallExpression = 159,
  ArgList = 160,
  ParenthesizedExpression = 161,
  Aggregator = 162,
  BinaryExpression = 166,
  CompareOp2 = 172,
  CompareOp1 = 173,
  GAccumAssignStmt = 178,
  LAccumAssignStmt = 179,
  DeclStmt = 180,
  BaseDeclStmt = 181,
  AccumDeclStmt = 182,
  AccumType = 183,
  Type = 184,
  ElementType = 185,
  FileDeclStmt = 186,
  FuncCallStmt = 187,
  CaseStmt = 188,
  ForEachStmt = 189,
  ForEachControl = 190,
  RaiseStmt = 192,
  TryStmt = 193,
  LogStmt = 194,
  SelectStmt = 195,
  GSQLSelectClause = 196,
  FromClause = 197,
  PathPattern = 198,
  StepVertexSet = 199,
  PathEdgePattern = 200,
  SampleClause = 201,
  WhereClause = 202,
  AccumClause = 203,
  DmlSubStmtList = 204,
  GAccumAccumStmt = 205,
  LocalVarDeclStmt = 207,
  LAccumAccumStmt = 208,
  DmlSubCaseStmt = 209,
  DmlSubWhileStmt = 210,
  DmlSubIfStmt = 211,
  DmlSubForEachStmt = 212,
  InsertStmt = 213,
  DmlSubDeleteStmt = 214,
  PostAccumClause = 215,
  HavingClause = 216,
  OrderClause = 217,
  LimitClause = 218,
  SQLSelectClause = 219,
  GroupByClause = 220,
  PrintStmt = 221,
  ReturnStmt = 222,
  WhileStmt = 223,
  IfStmt = 224,
  DeleteStmt = 225,
  UpdateStmt = 226
