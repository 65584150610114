import { highlightActiveLineGutter, keymap, tooltips, EditorView } from '@codemirror/view';
import { linter } from '@codemirror/lint';
import { defaultKeymap, indentLess, indentMore } from '@codemirror/commands';
import ReactCodeMirror, { Statistics } from '@uiw/react-codemirror';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { EditorState } from '@codemirror/state';
import { SelectionRange, Text } from '@codemirror/state';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { useDebounceEffect } from 'ahooks';
import { TreeCursor } from '@lezer/common';
import { useEditorContext } from '@/contexts/graphEditorContext';
import useEditorTheme from '@/pages/editor/useEditorTheme';
import { syntaxTree } from '@codemirror/language';
import { moveCompletionSelection, completionStatus, acceptCompletion } from '@codemirror/autocomplete';
import { gsql } from '@/pages/editor/GSQL/script';
import createTheme from '@/pages/editor/GSQL/theme';
import { useTheme } from '@/contexts/themeContext';
import { normalizeErrorOrWarning, codeCheckReq } from '../query/codeCheck';
import { WorkspaceT } from '@/pages/workgroup/type';
import { findParent, getQueryGraphName } from '@/pages/editor/GSQL/complete';

function gsqlLinter(wp: WorkspaceT | undefined) {
  return linter(async (view: EditorView) => {
    const at = syntaxTree(view.state).resolveInner(view.state.selection.main.from, -1);
    if (!at || !wp) return [];
    const queryBlockNode = findParent(at, ['QueryBlock']);
    if (!queryBlockNode) return [];

    const graphName = getQueryGraphName(view.state.doc, at);
    if (!graphName) return [];

    const queryStartLine = view.state.doc.lineAt(queryBlockNode.from).number;
    const queryCode = view.state.doc.sliceString(queryBlockNode.from, queryBlockNode.to);
    const isCypher = !!queryBlockNode.getChild('CreateQueryModifiers')?.getChild('OPENCYPHER');
    const result = await codeCheckReq({ code: queryCode, graph: graphName }, { version: wp.tg_version });
    if (result.error) return [];

    const errors = result.results!['errors'].map((error) => {
      const normalized = normalizeErrorOrWarning(queryCode, error, isCypher ? 'CYPHER' : 'GSQL');
      return formatDiagnostic(normalized, 'error', view.state.doc, queryStartLine);
    });
    const warnings = result.results!['warnings'].map((warning) => {
      const normalized = normalizeErrorOrWarning(queryCode, warning, isCypher ? 'CYPHER' : 'GSQL');
      return formatDiagnostic(normalized, 'warning', view.state.doc, queryStartLine);
    });

    return [...errors, ...warnings];
  });
}

type Position = {
  line: number;
  ch: number;
};

function posToOffset(doc: Text, pos: Position) {
  return doc.line(pos.line + 1).from + pos.ch;
}

function formatDiagnostic(diag: any, severity: 'error' | 'warning', doc: Text, startLine: number) {
  return {
    from: Math.min(
      posToOffset(doc, {
        line: diag.startLine + startLine - 1,
        ch: diag.startColumn,
      }),
      doc.length
    ),
    to: Math.min(
      posToOffset(doc, {
        line: diag.endLine + startLine - 1,
        ch: diag.endColumn,
      }),
      doc.length
    ),
    severity: severity,
    message: diag.message,
  };
}

export const GSQLEditorClassName = 'cm-gsql-container';

function printSyntaxTree(node: TreeCursor, depth: number = 0, state: EditorState) {
  const indent = '  '.repeat(depth);
  console.log(`${indent}${node.name}${node.from === node.to ? '' : `: "${state.sliceDoc(node.from, node.to)}"`}`);

  if (node.firstChild()) {
    do {
      printSyntaxTree(node, depth + 1, state);
    } while (node.nextSibling());
    node.parent();
  }
}

export interface CodeEditorProps {
  value: string;
  editable?: boolean;
  enableLint: boolean;
  onRunAll: () => void;
  onRunSelection: (code: string) => void;
  onCodeChange: (code: string) => void;
  onSaveFile: (code: string) => void;
  onSaveAll: () => Promise<void>;
}

export function CodeEditor({
  value,
  editable = false,
  enableLint,
  onRunAll,
  onRunSelection,
  onCodeChange,
  onSaveFile,
  onSaveAll,
}: CodeEditorProps) {
  const { currentWorkspace } = useWorkspaceContext();
  const { selectedCode, setSelectedCode } = useEditorContext();
  const [range, setRange] = useState<SelectionRange | null>(null); // real-time range
  const [runRange, setRunRange] = useState<SelectionRange | null>(null); // range when click the run button
  const [isRunSelection, setIsRunSelection] = useState(false);
  const { themeType } = useTheme();

  const [editorValue, setEditorValue] = useState(value);

  const codemirrorRef = useRef<any>();

  useDebounceEffect(
    () => {
      onCodeChange(editorValue);
    },
    [editorValue, onCodeChange],
    { wait: 200, maxWait: 1000 }
  );

  const customKeymap = useMemo(() => {
    return [
      {
        key: 'Mod-Enter',
        // prevent default behavior of codemirror
        // we implement the shortcut in Header.tsx
        run: () => true,
      },
      {
        key: 'Tab',
        shift: indentLess,
        preventDefault: true,
        run: (view: EditorView) => {
          if (completionStatus(view.state)) {
            return acceptCompletion(view);
          }
          return indentMore(view);
        },
      },
      {
        key: 'Ctrl-p',
        run: moveCompletionSelection(false),
      },
      {
        key: 'Ctrl-n',
        run: moveCompletionSelection(true),
      },
      ...defaultKeymap,
    ];
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = /Mac|iPad/i.test(navigator.platform);
      const modifierKey = isMac ? event.metaKey : event.ctrlKey;

      if (modifierKey && event.key === 's') {
        if (event.shiftKey) {
          onSaveAll();
        } else if (codemirrorRef?.current?.view) {
          const content = codemirrorRef.current.view.state.doc.toString();
          onSaveFile(content);
        }
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSaveFile, onSaveAll]);

  const handleCodeChange = useCallback((value: string) => {
    setIsRunSelection(false);
    setEditorValue(value);

    // @ts-ignore
    if (codemirrorRef.current && window.printAST) {
      const tree = syntaxTree(codemirrorRef.current.view.state);
      console.log('Syntax Tree:');
      printSyntaxTree(tree.cursor(), 0, codemirrorRef.current.view.state);
    }
  }, []);

  const handleStatisticChange = useCallback(
    (value: Statistics) => {
      setRange(value.selectionAsSingle);
      setSelectedCode(value.selectionCode);
    },
    [setSelectedCode]
  );

  const placeholder = useMemo(() => {
    const span = document.createElement('span');
    span.innerHTML =
      'Type your GSQL here, or explore our ' +
      '<a href="https://github.com/tigergraph/ecosys/blob/master/demos/guru_scripts/docker/tutorial/4.x/README.md" ' +
      'target="_blank" rel="noopener noreferrer" style="pointer-events: all;font-weight: 700;text-decoration: underline;">GSQL Tutorial</a> for guidance.';
    return span;
  }, []);

  const extensions = useMemo(() => {
    return [
      keymap.of(customKeymap),
      highlightActiveLineGutter(),
      tooltips({
        position: 'absolute',
        tooltipSpace(view) {
          return view.dom.getBoundingClientRect();
        },
      }),
      gsql(),
      createTheme(themeType),
      gsqlLinter(currentWorkspace),
    ];
  }, [customKeymap, themeType, currentWorkspace]);

  const basicSetup = useMemo(() => {
    return {
      defaultKeymap: false,
      highlightActiveLineGutter: false,
      highlightActiveLine: false,
    };
  }, []);

  const theme = useEditorTheme();

  const codeEditor = useMemo(() => {
    return (
      <ReactCodeMirror
        value={editorValue}
        className={GSQLEditorClassName}
        height={'100%'}
        theme={theme}
        onChange={handleCodeChange}
        editable={editable}
        extensions={extensions}
        basicSetup={basicSetup}
        onStatistics={handleStatisticChange}
        ref={codemirrorRef}
        indentWithTab={false}
        placeholder={placeholder}
      />
    );
  }, [editorValue, theme, handleCodeChange, editable, extensions, basicSetup, handleStatisticChange, placeholder]);

  return <>{codeEditor}</>;
}
