import { ErrorDisplay } from '@/components/error';
import { LoadingIndicator } from '@/components/loading-indicator';
import { Drawer, DrawerHeader } from '@/components/Drawer';

import { WorkGroupT, isStatusActive } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import { TableBuilderColumn } from 'baseui/table-semantic';
import toast from 'react-hot-toast';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ConfirmStatefulPopover } from '@/components/confirmPopover';
import { useMutationDeleteQuickInsights } from '@/insights/cloud/hook';
import { ApplicationT } from '@/insights/cloud/type';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import { Interval, format, formatDuration, intervalToDuration } from 'date-fns';
import { Eye, Trash2Icon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { TableContainer } from '@/lib/styled';
import { useQuery } from 'react-query';
import { Result } from '@/lib/type';
import { AxiosError } from 'axios';
import { listQuickInsights } from '@/insights/cloud/api';
import { useAtom } from 'jotai';
import { quickInsightsAtom } from '@/pages/workgroup/atom';
import { useOrgContext } from '@/contexts/orgContext';
import { calculateRoleForSpace } from '@/pages/admin/user/type';
import LinkButton from '@/components/LinkButton';
import { useState } from 'react';
import { parseDate } from '@/lib/date';
import { actionColumnOverrides } from '@/components/table';
import { useAccess } from '@/contexts/accessContext';
import CreateQuickInsights from '@/pages/dashboard/createInsights';
import InsightsIcon from '@/pages/workgroup/tab/icons/insights.svg?react';
import { AccessBlocked } from '@/pages/workgroup/icon';
import EmptyState from '@/pages/workgroup/EmptyState';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import { TRIGGER_TYPE } from 'baseui/popover';
import { useTheme } from '@/contexts/themeContext';
import EmptyIcon from './icon/empty.svg';
import EmptyDarkIcon from './icon/empty-dark.svg';

type Props = {
  group: WorkGroupT;
};

export default function QuickInsightLists({ group }: Props) {
  const [css, theme] = useStyletron();
  const { themeType } = useTheme();
  const navigate = useNavigate();
  const { userInfo } = useOrgContext();
  const myIP = useAccess();
  const [showCreateInsights, setShowCreateInsights] = useState(false);

  const [quickInsights, setQuickInsights] = useAtom(quickInsightsAtom);
  const { data, isLoading, isError, error } = useQuery<Result<ApplicationT[]>, AxiosError>(
    ['quick_insights', group.workgroup_id],
    async () => {
      return listQuickInsights(group.workgroup_id);
    },
    {
      onSuccess: (data) => {
        const results = data?.Result || [];
        for (let application of results) {
          if (application.cache_status === 'pending') {
            if (!quickInsights.find((item) => item.id === application.id)) {
              setQuickInsights((quickInsights) => quickInsights.concat(application));
            }
          }
        }
      },
    }
  );

  const deleteApplicationMutation = useMutationDeleteQuickInsights();

  const onDeleteApplication = (workgroup_id: string, workspace_id: string, app_id: string) => {
    const promise = deleteApplicationMutation.mutateAsync({
      workgroup_id,
      workspace_id,
      app_id,
    });
    toast.promise(
      promise,
      {
        loading: 'Loading',
        success: (data) => `${data.Message}`,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const [insightID, setInsightID] = useState<string | undefined>(undefined);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <ErrorDisplay label="Server Error:" error={error} />;
  }

  const { workspaces, tg_databases } = group;
  const apps = (data?.Result || [])
    .sort((a1, a2) => {
      const d1 = new Date(a1.created_at);
      const d2 = new Date(a2.created_at);
      return d1.getTime() - d2.getTime();
    })
    .map((application) => {
      const { id, workgroup_id, workspace_id, created_at, updated_at, cache_status, cache_error, default_graph } =
        application;
      let database;
      let workspace;
      for (let ws of workspaces) {
        if (ws.workspace_id === workspace_id) {
          workspace = ws;
          for (let db of tg_databases) {
            if (db.database_id === ws.database_id) {
              database = db;
              break;
            }
          }
          break;
        }
      }
      return {
        id,
        workgroup_id,
        workspace_id,
        created_at,
        updated_at,
        cache_status,
        cache_error,
        database,
        workspace,
        default_graph,
      };
    });

  const selectInsights = apps.find((app) => app.id === insightID);

  let haveSpaceAdmin = false;
  for (let ws of group.workspaces) {
    if (calculateRoleForSpace(userInfo.roles, group.workgroup_id, ws.workspace_id) === 'workspace-admins') {
      haveSpaceAdmin = true;
      break;
    }
  }

  const noPermissionTips = (
    <StatefulTipsPopover
      content="In the current workgroup, you do not have permission to generate data profile."
      triggerType={TRIGGER_TYPE.hover}
      focusLock={true}
      overrides={{
        Body: {
          style: {
            maxWidth: '450px',
          },
        },
      }}
    >
      <Button
        size="large"
        startEnhancer={<InsightsIcon />}
        overrides={{
          BaseButton: {
            style: {
              opacity: 0.5,
            },
          },
        }}
      >
        Create Data Profile
      </Button>
    </StatefulTipsPopover>
  );

  return (
    <>
      {apps.length > 0 && (
        <div
          className={css({
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '16px',
          })}
        >
          {haveSpaceAdmin ? (
            <Button onClick={() => setShowCreateInsights(true)} size="large" startEnhancer={<InsightsIcon />}>
              Create Data Profile
            </Button>
          ) : (
            noPermissionTips
          )}
        </div>
      )}
      {apps.length > 0 && (
        <TableBuilder data={apps}>
          <TableBuilderColumn header="Database" id="database">
            {(row) => row.database?.name}
          </TableBuilderColumn>
          <TableBuilderColumn header="Workspace" id="workspace">
            {(row) => row.workspace?.name}
          </TableBuilderColumn>
          <TableBuilderColumn header="Graph" id="graph">
            {(row) => row.default_graph}
          </TableBuilderColumn>
          <TableBuilderColumn header="Status" id="status">
            {(row) => (
              <div className={css({ display: 'flex', whiteSpace: 'nowrap' })}>
                <Status application={row} />
                {row.cache_status === 'error' && (
                  <LinkButton $style={{ fontSize: '12px' }} onClick={() => setInsightID(row.id)}>
                    Err Log
                  </LinkButton>
                )}
              </div>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn header="Created on" id="createDate">
            {(row) => format(parseDate(row.created_at), 'yyyy-MM-dd HH:mm:ss')}
          </TableBuilderColumn>
          <TableBuilderColumn header="Updated on" id="updateDate">
            {(row) => format(parseDate(row.updated_at), 'yyyy-MM-dd HH:mm:ss')}
          </TableBuilderColumn>
          <TableBuilderColumn header="Age" id="age">
            {(row) => {
              const now = new Date();
              const updated_at = parseDate(row.updated_at);
              const interval: Interval = {
                start: updated_at,
                end: now,
              };

              const duration = intervalToDuration(interval);

              if (duration && duration.months && duration.months >= 1) {
                return formatDuration(duration, {
                  format: ['years', 'months'],
                });
              } else if (duration && duration.days && duration.days >= 1) {
                return formatDuration(duration, {
                  format: ['days'],
                });
              } else if (duration && duration.hours && duration.hours >= 1) {
                return formatDuration(duration, {
                  format: ['hours'],
                });
              } else {
                return formatDuration(duration, {
                  format: ['minutes', 'seconds'],
                });
              }
            }}
          </TableBuilderColumn>
          <TableBuilderColumn header="Actions" overrides={actionColumnOverrides}>
            {(row) => {
              const effectRole = calculateRoleForSpace(userInfo.roles, row.workgroup_id, row.workspace_id);
              return (
                <div
                  className={css({
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    columnGap: '8px',
                  })}
                >
                  {/* allow view data profile in error status */}
                  {(row.cache_status === 'success' || row.cache_status === 'error') && (
                    <>
                      {isStatusActive(row.workspace?.status) ? (
                        <Button
                          kind="text"
                          shape="square"
                          disabled={!myIP.is_allowed}
                          onClick={() => {
                            navigate(`/groups/${group.workgroup_id}/dataprofiles/${row.id}?tab=dataprofiles`);
                          }}
                        >
                          {myIP.is_allowed ? <Eye size={16} /> : <AccessBlocked />}
                        </Button>
                      ) : (
                        <StatefulTipsPopover
                          content="The workspace is not active, so you cannot view the data profile."
                          triggerType={TRIGGER_TYPE.hover}
                          autoFocus={false}
                          focusLock={true}
                          overrides={{
                            Body: {
                              style: {
                                maxWidth: '450px',
                              },
                            },
                          }}
                        >
                          <Button
                            kind="text"
                            shape="square"
                            overrides={{
                              BaseButton: {
                                style: {
                                  opacity: 0.5,
                                },
                              },
                            }}
                          >
                            <Eye size={16} />
                          </Button>
                        </StatefulTipsPopover>
                      )}
                    </>
                  )}
                  {effectRole === 'workspace-admins' ? (
                    <ConfirmStatefulPopover
                      confirmLabel="Confirm to delete"
                      onConfirm={() => onDeleteApplication(row.workgroup_id, row.workspace_id, row.id)}
                    >
                      <Button kind="text" shape="square">
                        <Trash2Icon size={16} />
                      </Button>
                    </ConfirmStatefulPopover>
                  ) : null}
                </div>
              );
            }}
          </TableBuilderColumn>
        </TableBuilder>
      )}
      {workspaces.length == 0 && !selectInsights && <EmptyState />}
      {workspaces.length > 0 && !selectInsights && apps.length === 0 && (
        <>
          {haveSpaceAdmin ? (
            <TableContainer>
              <Container>
                <Body2>Click to generate your data profile.</Body2>
                <Button onClick={() => setShowCreateInsights(true)} size="large" startEnhancer={<InsightsIcon />}>
                  Create Data Profile
                </Button>
              </Container>
            </TableContainer>
          ) : (
            <div
              className={css({
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              })}
            >
              <img src={themeType === 'light' ? EmptyIcon : EmptyDarkIcon} />
              {noPermissionTips}
            </div>
          )}
        </>
      )}
      {selectInsights ? (
        <Drawer isOpen={!!selectInsights} onClose={() => setInsightID(undefined)}>
          <DrawerHeader>Data Profile Error Log</DrawerHeader>
          <pre
            className={css({
              width: '658px',
              height: '100%',
              overflowY: 'auto',
              whiteSpace: 'pre-wrap',
              backgroundColor: 'black',
              color: theme.colors.gray200,
              fontSize: '12px',
              padding: '8px',
            })}
          >
            {selectInsights.cache_error}
          </pre>
        </Drawer>
      ) : null}
      {showCreateInsights ? (
        <CreateQuickInsights isOpen={showCreateInsights} onClose={() => setShowCreateInsights(false)} group={group} />
      ) : null}
    </>
  );
}

function Status({ application }: { application: ApplicationT }) {
  const { cache_status } = application;
  if (cache_status === 'success') {
    return (
      <Tag closeable={false} kind="positive">
        Ready
      </Tag>
    );
  }

  if (cache_status === 'error') {
    return (
      <Tag closeable={false} kind="negative">
        Failed
      </Tag>
    );
  }

  if (cache_status === 'pending') {
    return (
      <Tag
        closeable={false}
        kind="neutral"
        overrides={{
          Text: {
            style: {
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            },
          },
        }}
      >
        In progress
        <Spinner $size="12px" $borderWidth="2px" />
      </Tag>
    );
  }

  return null;
}

const Container = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: $theme.colors.gray1000,
  ...$theme.typography.Body1,
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  height: '100%',
  textAlign: 'center',
  gap: '16px',
}));
