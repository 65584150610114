// doc: https://docs.tigergraph.com/gsql-ref/4.1/appendix/keywords-and-reserved-words
export const kws = [
  'ACCUM',
  'ADD',
  'ALL',
  'ALLOCATE',
  'ALTER',
  'ANY',
  'ASC',
  'BAG',
  'BETWEEN',
  'BOOL',
  'BY',
  'CALL',
  'COALESCE',
  'COMPRESS',
  'COUNT',
  'CREATE',
  'KAFKA',
  'S3',
  'DATETIME_ADD',
  'DATETIME_SUB',
  'DATETIME_DIFF',
  'DATETIME_TO_EPOCH',
  'DATETIME_FORMAT',
  'DELETE',
  'DESC',
  'DISTRIBUTED',
  'DROP',
  'DATETIME',
  'DOUBLE',
  'EPOCH_TO_DATETIME',
  'EDGE',
  'ESCAPE',
  'FALSE',
  'FILE',
  'FIXED_BINARY',
  'FLOAT',
  'FROM',
  'GLOBAL',
  'GRAPH',
  'GROUP',
  'HAVING',
  'HEADER',
  'INDEX',
  'INPUT_LINE_FILTER',
  'INSERT',
  'INT',
  'INTERPRET',
  'INTO',
  'ISEMPTY',
  'JOB',
  'LIKE',
  'LIMIT',
  'NOT',
  'NOW',
  'NULL',
  'OPENCYPHER',
  'OR',
  'ORDER',
  'PRIMARY_ID',
  'QUERY',
  'REDUCE',
  'REPLACE',
  'SAMPLE',
  'SELECT',
  'SELECTVERTEX',
  'SET',
  'STRING',
  'SUM',
  'TEMP_TABLE',
  'TO',
  'TO_CSV',
  'TO_DATETIME',
  'TRIM',
  'TRUE',
  'TUPLE',
  'UINT',
  'UPDATE',
  'VALUES',
  'VERTEX',
  'WHERE',
  'AVG',
  'ABORT',
  'ACL',
  'API',
  'ATTRIBUTE',
  'CONCAT',
  'DATA',
  'DATA_SOURCE',
  'DEFINE',
  'DIRECTED',
  'EMPTY',
  'EXPORT',
  'FILENAME',
  'FLATTEN',
  'FLATTEN_JSON_ARRAY',
  'GET',
  'GRANT',
  'IMPORT',
  'INSTALL',
  'JSON',
  'LOADING',
  'LOCAL',
  'LS',
  'MAX',
  'MIN',
  'MINUS',
  'OVERWRITE',
  'OWNER',
  'PAIR',
  'PASSWORD',
  'PRIVILEGE',
  'PUT',
  'READ',
  'REJECT_LINE_RULE',
  'RESUME',
  'REVOKE',
  'ROLE',
  'RUN',
  'SCHEMA',
  'SCHEMA_CHANGE',
  'SECONDARY_ID',
  'SECRET',
  'SEPARATOR',
  'SHOW',
  'SPLIT',
  'STATS',
  'STATUS',
  'STORE',
  'SUBSTR',
  'SYNTAX',
  'TAG',
  'TEMPLATE',
  'TOKEN',
  'TOKEN_LEN',
  'TOKENBANK',
  'TO_FLOAT',
  'TO_INT',
  'UNDIRECTED',
  'USE',
  'USER',
];
