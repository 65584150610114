import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { WorkGroupT, WorkspaceT, isStatusActive } from '@/pages/workgroup/type';
import { WorkspaceSelector } from '@/pages/editor/WorkspaceSelector';
import { getGrafanaURL } from '@/utils/utils';
import { memo, useEffect, useState } from 'react';
import { LoadingIndicator } from '@/components/loading-indicator';
import { useOrgContext } from '@/contexts/orgContext';
import { calculateRoleForSpace } from '@/pages/admin/user/type';
import NoPermission from '@/components/NoPermission';
import { TableContainer } from '@/lib/styled';
import EmptyState from '@/pages/workgroup/EmptyState';
import { useTheme } from '@/contexts/themeContext';
import { useSearchParams } from 'react-router-dom';

const GrafanaIframe = memo(({ grafanaURL }: { grafanaURL: string }) => {
  const [css] = useStyletron();
  return <iframe title="monitor" src={grafanaURL} className={css({ width: '100%', height: '100%' })} />;
});

export function Monitor({ group }: { group: WorkGroupT }) {
  const [css, theme] = useStyletron();
  const { themeType } = useTheme();
  const [searchParams] = useSearchParams();
  const [currentWorkspace, setCurrentWorkspace] = useState<WorkspaceT | undefined>(() => {
    const workspace = group.workspaces.find((space) => space.workspace_id === searchParams.get('space_id'));
    if (workspace) {
      return workspace;
    }

    const activeWorkspaces = group.workspaces.filter((space) => isStatusActive(space.status));

    if (activeWorkspaces.length > 0) {
      return activeWorkspaces[0];
    }
    return group.workspaces[0];
  });

  const { userInfo } = useOrgContext();

  const [showLoading, setShowLoading] = useState(false);

  const grafanaURL = currentWorkspace
    ? getGrafanaURL(group.org_id, currentWorkspace.workspace_id, currentWorkspace.workgroup_id, themeType)
    : '';

  useEffect(() => {
    if (!customElements) {
      return;
    }
    setShowLoading(true);
    const handleIframeMessage = (event: MessageEvent<{ type: string }>) => {
      if (event.data.type === 'iframe-ready') {
        setShowLoading(false);
      }
    };
    window.addEventListener('message', handleIframeMessage, false);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [currentWorkspace]);

  const userRoleForSpace = currentWorkspace
    ? calculateRoleForSpace(userInfo.roles, group.workgroup_id, currentWorkspace.workspace_id)
    : 'users';
  const hasPermission = userRoleForSpace === 'workspace-users' || userRoleForSpace === 'workspace-admins';

  return (
    <>
      {group.workspaces.length == 0 && (
        <TableContainer>
          <EmptyState group={group} />
        </TableContainer>
      )}
      {group.workspaces.length > 0 && (
        <div
          className={css({
            height: 'calc(100vh - 89px)',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          })}
        >
          <div className={css({ marginBottom: '16px' })}>
            <WorkspaceSelector
              isLocalSelector={true}
              workspace={currentWorkspace}
              onWorkspaceChange={setCurrentWorkspace}
              workgroupId={group.workgroup_id}
              enableAllSelect
            />
          </div>
          <div className={css({ width: '100%', flexGrow: 1, position: 'relative' })}>
            {!hasPermission ? (
              <NoPermission />
            ) : (
              <>
                {showLoading && (
                  <div
                    className={css({
                      position: 'absolute',
                      background: `${theme.colors['background.primary']}`,
                      width: '100%',
                      height: '100%',
                    })}
                  >
                    <LoadingIndicator />
                  </div>
                )}
                {currentWorkspace && <GrafanaIframe grafanaURL={grafanaURL} />}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
