import IconButton from '@/components/IconButton';
import { Sidebar } from '@/components/Sidebar';
import SchemaDesigner from '@/components/schemaDesigner';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useOrgContext } from '@/contexts/orgContext';
import { calculateRoleForGroup, calculateRoleForSpace } from '@/pages/admin/user/type';
import {
  useMutationDeleteDatabase,
  useMutationDeleteWorkspace,
  useMutationPauseWorkspace,
  useMutationResumeWorkspace,
  useMutationUpdateWorkspace,
} from '@/pages/workgroup/hook';
import {
  DatabaseT,
  WorkspaceT,
  getWorkspacePrice,
  isStatusActive,
  isStatusError,
  isStatusIdle,
  isStatusPaused,
  isStatusPending,
} from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { Body2, Label } from '@tigergraph/app-ui-lib/typography';
import { Overflow } from 'baseui/icon';
import { useState } from 'react';
import toast from 'react-hot-toast';

import AccessIcon from './icons/access.svg?react';
import DeleteIcon from './icons/delete.svg?react';
import EditIcon from './icons/edit.svg?react';
import PauseIcon from './icons/pause.svg?react';
import StartIcon from './icons/start.svg?react';
import StopIcon from './icons/stop.svg?react';
import { MdOutlineMonitorHeart } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import SpaceUserManagement from '@/pages/workgroup/tab/iam/SpaceUserManagement';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import clsx from 'clsx';
import { formatPricePerHour } from '@/utils/format';
import currency from 'currency.js';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Tag } from '@tigergraph/app-ui-lib/tag';
import WorkspaceSetting from '@/pages/workgroup/form/WorkspaceSetting';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { listSolution } from '@/pages/marketplace/solution/api';

export function SettingMenuForWorkSpace({
  workspace,
  database,
  popover,
}: {
  workspace: WorkspaceT;
  database?: DatabaseT;
  popover?: boolean;
}) {
  const navigate = useNavigate();
  const [css, theme] = useStyletron();
  const { userInfo } = useOrgContext();

  const deleteWorkSpaceMutation = useMutationDeleteWorkspace();
  const pauseWorkSpaceMutation = useMutationPauseWorkspace();
  const updateWorkSpaceMutation = useMutationUpdateWorkspace();

  const [showSchemaDesigner, setShowSchemaDesigner] = useState(false);
  const [showWorkspaceSetting, setShowWorkspaceSetting] = useState(false);
  const [showResume, setShowResume] = useState(false);

  const [showSpaceAdminDetail, setShowSpaceAdminDetail] = useState(false);

  const [showConfirmStop, setShowConfirmStop] = useState(false);
  const [showConfirmPause, setShowConfirmPause] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const onPause = () => {
    const mutate = async () => {
      // check if there are any in progress solutions in the workspace
      const data = await listSolution(workspace.workgroup_id);
      if (data.Result) {
        for (const solution of data.Result) {
          if (solution.workspace_id === workspace.workspace_id && solution.solution_status === 'install_pending') {
            throw new Error(
              'The current workspace is in the process of installing a solution and cannot be paused at this time.'
            );
          }
        }
      }

      const promise2 = pauseWorkSpaceMutation.mutateAsync({
        group_id: workspace.workgroup_id,
        space_id: workspace.workspace_id,
      });

      await promise2;
    };

    toast.promise(
      mutate(),
      {
        loading: 'Pausing workspace',
        success: () => 'Request has been accepted',
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const onStop = () => {
    const mutate = async () => {
      // check if there are any in progress solutions in the workspace
      const data = await listSolution(workspace.workgroup_id);
      if (data.Result) {
        for (const solution of data.Result) {
          if (solution.workspace_id === workspace.workspace_id && solution.solution_status === 'install_pending') {
            throw new Error(
              'The current workspace is in the process of installing a solution and cannot be stopped at this time.'
            );
          }
        }
      }

      if (workspace.enable_auto_start) {
        // set auto start to false
        const promise1 = updateWorkSpaceMutation.mutateAsync({
          group_id: workspace.workgroup_id,
          space_id: workspace.workspace_id,
          data: {
            enable_auto_start: false,
          },
        });

        await promise1;
      }

      const promise2 = pauseWorkSpaceMutation.mutateAsync({
        group_id: workspace.workgroup_id,
        space_id: workspace.workspace_id,
      });

      await promise2;
    };

    toast.promise(
      mutate(),
      {
        loading: 'Stopping workspace',
        success: () => 'Request has been accepted',
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const onTerminate = () => {
    const promise = deleteWorkSpaceMutation.mutateAsync({
      group_id: workspace.workgroup_id,
      space_id: workspace.workspace_id,
    });

    toast.promise(
      promise,
      {
        loading: 'Deleting workspace',
        success: (data) => data.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const effectRole = calculateRoleForSpace(userInfo.roles, workspace.workgroup_id, workspace.workspace_id);

  return (
    <>
      <DropdownMenu>
        <Sidebar isDrawer={true} isOpen={showSchemaDesigner} onClose={() => setShowSchemaDesigner(false)}>
          <SchemaDesigner title={'Schema Designer'} showHideButton={false} isInDrawer={true} />
        </Sidebar>
        <DropdownMenuTrigger asChild>
          {popover ? (
            <IconButton>
              <Overflow size={24} />
            </IconButton>
          ) : (
            <Button shape="square" kind="secondary">
              <Overflow size={16} />
            </Button>
          )}
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={clsx(...css({ width: '190px', marginLeft: '165px' }), popover ? 'workspace-popover' : null)}
          align="start"
        >
          {effectRole === 'workspace-admins' ? (
            <DropdownMenuItem onClick={() => setShowWorkspaceSetting(true)}>
              <EditIcon />
              <Body2>Edit</Body2>
            </DropdownMenuItem>
          ) : null}
          {effectRole === 'workspace-admins' ? (
            <DropdownMenuItem
              onClick={() => {
                setShowSpaceAdminDetail(true);
              }}
            >
              <AccessIcon />
              <Body2>Manage Access</Body2>
            </DropdownMenuItem>
          ) : null}
          <DropdownMenuItem
            onClick={() => {
              navigate(`/groups/${workspace.workgroup_id}?tab=monitor&space_id=${workspace.workspace_id}`);
            }}
          >
            <MdOutlineMonitorHeart size={18} />
            <Body2>Monitor</Body2>
          </DropdownMenuItem>
          {effectRole === 'workspace-admins' ? (
            <>
              <DropdownMenuSeparator />
              {isStatusPaused(workspace.status) || isStatusIdle(workspace.status) ? (
                <DropdownMenuItem
                  onClick={() => {
                    setShowResume(true);
                  }}
                >
                  <StartIcon />
                  <Body2>Resume</Body2>
                </DropdownMenuItem>
              ) : null}
              {isStatusActive(workspace.status) ||
              isStatusError(workspace.status) ||
              isStatusPending(workspace.status) ? (
                <>
                  <DropdownMenuItem
                    onClick={() => {
                      setShowConfirmStop(true);
                    }}
                  >
                    <StopIcon />
                    <Body2>Stop</Body2>
                  </DropdownMenuItem>
                  {/* only show pause when enable_auto_start */}
                  {workspace.enable_auto_start && (
                    <DropdownMenuItem
                      onClick={() => {
                        setShowConfirmPause(true);
                      }}
                    >
                      <PauseIcon />
                      <Body2>Pause</Body2>
                    </DropdownMenuItem>
                  )}
                </>
              ) : null}
              <DropdownMenuItem
                onClick={() => setShowDeleteConfirm(true)}
                className={css({
                  color: theme.colors['icon.danger'],
                })}
              >
                <DeleteIcon />
                <Body2 $style={{ color: 'inherit' }}>Terminate</Body2>
              </DropdownMenuItem>
            </>
          ) : null}
        </DropdownMenuContent>
      </DropdownMenu>
      {showWorkspaceSetting ? (
        <WorkspaceSetting
          isOpen={showWorkspaceSetting}
          onClose={() => setShowWorkspaceSetting(false)}
          workspace={workspace}
        />
      ) : null}
      {showSpaceAdminDetail ? (
        <SpaceUserManagement
          space={workspace}
          // @ts-ignore
          group={{ workgroup_id: workspace.workgroup_id }}
          isOpen={showSpaceAdminDetail}
          onClose={() => setShowSpaceAdminDetail(false)}
        />
      ) : null}
      {showConfirmStop ? (
        <ConfirmStop
          isOpen={showConfirmStop}
          onCancel={() => setShowConfirmStop(false)}
          onConfirm={() => {
            setShowConfirmStop(false);
            onStop();
          }}
          name={workspace.name}
          enable_auto_start={workspace.enable_auto_start}
        />
      ) : null}
      {showConfirmPause ? (
        <ConfirmPause
          isOpen={showConfirmPause}
          onCancel={() => setShowConfirmPause(false)}
          onConfirm={() => {
            setShowConfirmPause(false);
            onPause();
          }}
          name={workspace.name}
          enable_auto_start={workspace.enable_auto_start}
        />
      ) : null}
      {showResume ? (
        <ConfirmResumeWorkspace isOpen={showResume} onClose={() => setShowResume(false)} workspace={workspace} />
      ) : null}
      {showDeleteConfirm ? (
        <ConfirmDeleteWorkspace
          isOpen={showDeleteConfirm}
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={() => {
            setShowDeleteConfirm(false);
            onTerminate();
          }}
          name={workspace.name}
          isRW={workspace.is_rw}
        />
      ) : null}
    </>
  );
}

export function SettingMenuForDatabase({ database, disableDelete }: { database: DatabaseT; disableDelete: boolean }) {
  const deleteDatabaseMutation = useMutationDeleteDatabase();
  const { userInfo } = useOrgContext();

  const onDelete = () => {
    const promise = deleteDatabaseMutation.mutateAsync({
      group_id: database.workgroup_id,
      db_id: database.database_id,
    });

    toast.promise(
      promise,
      {
        loading: 'Deleting database',
        success: (result) => result.Message!,
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const isGroupAdmin = calculateRoleForGroup(userInfo.roles, database.workgroup_id) === 'workgroup-admins';
  if (!isGroupAdmin) {
    return null;
  }
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button shape="square" kind="secondary">
            <Overflow size={16} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="start">
          <DropdownMenuItem disabled={disableDelete} onClick={() => setShowDeleteConfirm(true)}>
            Terminate
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {showDeleteConfirm ? (
        <ConfirmDeleteDatabase
          isOpen={showDeleteConfirm}
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={() => {
            setShowDeleteConfirm(false);
            onDelete();
          }}
          name={database.name}
        />
      ) : null}
    </>
  );
}

type ConfirmProps = {
  isOpen: boolean;
  name: string;
  onCancel: () => void;
  onConfirm: () => void;
  isRW?: boolean;
  enable_auto_start?: boolean;
};

function ConfirmPause({ isOpen, onCancel, onConfirm, name }: ConfirmProps) {
  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <ModalHeader>Pause the workspace?</ModalHeader>
      <ModalBody>
        Are you sure to pause workspace <strong>{name}</strong>? Auto resume is enabled, so the workspace can be
        automatically resumed when accessed.
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton onClick={onConfirm}>Confirm</ModalButton>
      </ModalFooter>
    </Modal>
  );
}

function ConfirmStop({ isOpen, onCancel, onConfirm, name, enable_auto_start }: ConfirmProps) {
  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <ModalHeader>Stop the workspace?</ModalHeader>
      <ModalBody>
        Are you sure to stop workspace <strong>{name}</strong>?{' '}
        {enable_auto_start ? 'Stopping will also disable auto resume.' : ''}
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton onClick={onConfirm}>Confirm</ModalButton>
      </ModalFooter>
    </Modal>
  );
}

function ConfirmDeleteWorkspace({ isOpen, onCancel, onConfirm, name, isRW }: ConfirmProps) {
  const [isConfirm, setIsConfirm] = useState(false);
  const [isRWConfirm, setIsRWConfirm] = useState(false);
  const [css, theme] = useStyletron();

  let disable = !isConfirm;
  if (isRW) {
    disable = !isConfirm || !isRWConfirm;
  }

  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <ModalHeader>Delete the workspace?</ModalHeader>
      <ModalBody>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          })}
        >
          <p>
            Are you sure to delete workspace <strong>{name}</strong>?
          </p>
          <p>
            {isRW
              ? 'Deleting the read-write workspace will permanently remove the ability to create a new read-write workspace and link it to the same database. Additionally, all backups associated with the workspace will also be deleted.'
              : 'If you proceed with deleting the read-only workspace, the associated database snapshot will also be permanently deleted. Please note that it cannot be restored later.'}
          </p>
          <div>
            <Checkbox
              checked={isConfirm}
              onChange={(e) => {
                setIsConfirm(e.currentTarget.checked);
              }}
            >
              <Label
                $style={{
                  marginTop: '1px',
                }}
              >
                {isRW
                  ? 'I understand that deleting the workspace will also delete the associated database backups.'
                  : 'I understand that deleting the workspace will also delete the associated database snapshots.'}
              </Label>
            </Checkbox>
            {isRW ? (
              <Checkbox
                checked={isRWConfirm}
                onChange={(e) => {
                  setIsRWConfirm(e.currentTarget.checked);
                }}
              >
                <Label
                  $style={{
                    marginTop: '1px',
                  }}
                >
                  I acknowledge that I will not be able to create a new read-write workspace against the same database.
                </Label>
              </Checkbox>
            ) : null}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton kind="destructive" onClick={onConfirm} disabled={disable}>
          Confirm
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}

function ConfirmDeleteDatabase({ isOpen, onCancel, onConfirm, name }: ConfirmProps) {
  return (
    <Modal onClose={onCancel} isOpen={isOpen}>
      <ModalHeader>Delete the database?</ModalHeader>
      <ModalBody>
        Are you sure to delete database <strong>{name}</strong>? Deleting the database will remove all the data stored
        inside it.
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton onClick={onConfirm}>Confirm</ModalButton>
      </ModalFooter>
    </Modal>
  );
}

export function ConfirmResumeWorkspace({
  isOpen,
  onClose,
  workspace,
}: {
  workspace: WorkspaceT;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { price, cps } = useWorkspaceContext();
  const resumeWorkSpaceMutation = useMutationResumeWorkspace();
  const { userInfo } = useOrgContext();
  const [css, theme] = useStyletron();
  const userRoleForSpace = workspace
    ? calculateRoleForSpace(userInfo.roles, workspace.workgroup_id, workspace.workspace_id)
    : 'users';
  const hasResumePermission = userRoleForSpace === 'workspace-admins';

  const className = css({
    color: theme.colors['text.primary'],
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  });

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalHeader>Resume the workspace?</ModalHeader>
      <ModalBody>
        <div
          className={css({
            fontSize: '14px',
          })}
        >
          The workspace you selected is paused/suspended. Are you sure you want to resume it?
        </div>
        <div
          className={css({
            marginTop: '16px',
            display: 'grid',
            gridTemplateColumns: '100px 1fr',
            backgroundColor: theme.colors['background.secondary'],
            padding: '8px',
            gap: '8px',
            fontSize: '12px',
            color: theme.colors['text.secondary'],
          })}
        >
          <div>Workgroup</div>
          <div className={className}>{workspace.workgroup_name}</div>
          <div>Workspace</div>
          <div className={className}>
            {workspace.name}
            <Tag
              kind="neutral"
              closeable={false}
              overrides={{
                Root: {
                  style: {
                    borderTopLeftRadius: '2px',
                    borderTopRightRadius: '2px',
                    borderBottomRightRadius: '2px',
                    borderBottomLeftRadius: '2px',
                    height: '16px',
                  },
                },
                Text: {
                  style: {
                    fontSize: '10px',
                    lineHeight: '12px',
                  },
                },
              }}
            >
              {workspace.is_rw ? 'Read Write' : 'Read Only'}
            </Tag>
          </div>
          <div>Workspace Size</div>
          <div className={className}>
            <Tag
              variant="outlined"
              closeable={false}
              overrides={{
                Root: {
                  style: {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    height: '18px',
                  },
                },
              }}
            >
              {workspace.workspace_type.typeName}
            </Tag>
            From {price ? formatPricePerHour(currency(getWorkspacePrice(price, workspace, cps))) : ''}
          </div>
          <div>Database</div>
          <div>{workspace.database_name}</div>
          <div>Auto-Resume</div>
          <div>{workspace.enable_auto_start ? 'On' : 'Off'}</div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="secondary" onClick={onClose}>
          Cancel
        </ModalButton>
        <ModalButton
          isLoading={resumeWorkSpaceMutation.isLoading}
          disabled={resumeWorkSpaceMutation.isLoading || !hasResumePermission}
          onClick={() => {
            resumeWorkSpaceMutation.mutate(
              {
                group_id: workspace.workgroup_id,
                space_id: workspace.workspace_id,
              },
              {
                onSuccess: () => {
                  onClose();
                  toast.success('Request has been accepted');
                },
                onError: (err) => {
                  toast.error(getErrorMessage(err));
                },
              }
            );
          }}
        >
          Confirm
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}
