// @ts-nocheck
// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {queryTokens, tokens, keywords} from "./scriptTokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "+^Q]QUOOOtQUO'#FpO!SQUO'#FpO!XQUO'#FrO!^QUO'#FsO!cQUO'#F|O!hQUO'#GPO!mQUO'#GQOOQQ'#GZ'#GZOOQQ'#GR'#GRQ]QUOOOOQQ'#Fq'#FqO!rQUO'#FqO!zQUO'#FqO#PQUO,5<[O#UQUO,5<[O#aQUO,5<^O#XQUO,5<_O#fQUO,5<hO#tQUO,5<kO$PQUO,5<lOOQQ-E:P-E:POOQQ,5<],5<]O$UQUO,5<]O$aQVO1G1vO$fQUO1G1vO$kQUO'#GSO$pQUO'#FtO$xQUO1G1yOOQQ1G1x1G1xO$}QUO'#F}O%]QUO1G2SO%hQUO'#GbOOQQ1G2V1G2VOOQQ1G2W1G2WOOQQ1G1w1G1wO&VQUO1G1wOOQQ7+'b7+'bO&_QVO7+'bOOQQ,5<n,5<nOOQQ-E:Q-E:QO&dQUO7+'eOOQQ7+'n7+'nO&iQUO'#GVO&nQUO,5<|OOQQ7+'c7+'cOOQQ<<J|<<J|O'qQUO<<KPOOQQ,5<q,5<qOOQQ-E:T-E:TOOQQ'#Fv'#FvO']QUO'#FwO'xQUO'#FxOOQQ'#G^'#G^O'}QUO'#G]OOQQ'#Fu'#FuO(YQUO'#FzO(bQUOAN@kO(gQUO,5<cO(lQUO,5<dO']QUO'#GTO(qQUO,5<wO(|QUO'#F{O)RQUO'#G`O)ZQUO,5<fOOQQG26VG26VOOQQ1G1}1G1}O)`QUO1G2OOOQQ,5<o,5<oOOQQ-E:R-E:RO']QUO,5<gO)eQUO'#GUO)pQUO,5<zOOQQ1G2Q1G2QO)xQUO7+'jOOQQ1G2R1G2ROOQQ,5<p,5<pOOQQ-E:S-E:SOOQQ<<KU<<KU",
  stateData: ")}~O$|OSSOSROS~OnPOyUO!_QO#eTO#xSO$OVO$_RO~OxZO!h[O!i]O!l$eP~O!l_O~O!V`O~O!laO~O!lbO~O!lcO~O!ldO~OxfO!l$eX~O!ngO~O!lhO~OViO%OjO^$hP~O^mO~O%OjO^$qPa$qP$r$qP~O^pOaqO$rqO~O^rO~OxsO!htO!l$ea~OPuO~OWvO~O^wO~O%OjO^$hX~O^yO~O%OjO^$qXa$qX$r$qX~O^pOazO$rzO~O%R{On%UXy%UX!_%UX#e%UX#x%UX$O%UX$_%UX$z%UX~Ox}O!l$ei~OP!OO~OV!PO~O^!QO~O%R{On%Uay%Ua!_%Ua#e%Ua#x%Ua$O%Ua$_%Ua$z%Ua~OQ!SOT!SOU!SOV!UOZ!TO$m!VO~OX!YO~P']OQ!]O~O%R!^OW%PX[%PX~OQ!`OY%SP~OW!cO~O[!dO~O%R!eO~O%R!^OW%Pa[%Pa~O%T!hO~O%R!iOY%SX~OY!kO~OQ!lO~OQ!`OY$xX%R$xX~O%R!iOY%Sa~OW!pO~O",
  goto: "'X%VPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP%W%[%W%W%_%c%f%f%fP%c%l%W%rP%W%W%u%{&W&^&dPPP&jP&n&tP'OP'RTWOYR^PTl_aR!Z!PX!V!P!T!^!hQ!a!YR!n!iRobQYOReYSk_aQnbTxknQ!_!WR!g!_Q!j!aR!o!jQ|pR!R|TXOYQ!X!PR![!TS!W!P!TQ!f!^R!m!hR!b!YQqcRzo",
  nodeNames: "⚠ QueryText String BlockComment LineComment Number Bool ( ) { } [ ] . Identifier ACCUM ADD ALL ALLOCATE ALTER ANY ASC BAG BETWEEN BOOL BY CALL COALESCE COMPRESS COUNT CREATE KAFKA S3 DATETIME_ADD DATETIME_SUB DATETIME_DIFF DATETIME_TO_EPOCH DATETIME_FORMAT DELETE DESC DISTRIBUTED DROP DATETIME DOUBLE EPOCH_TO_DATETIME EDGE ESCAPE FALSE FILE FIXED_BINARY FLOAT FROM GLOBAL GRAPH GROUP HAVING HEADER INDEX INPUT_LINE_FILTER INSERT INT INTERPRET INTO ISEMPTY JOB LIKE LIMIT NOT NOW NULL OPENCYPHER OR ORDER PRIMARY_ID QUERY REDUCE REPLACE SAMPLE SELECT SELECTVERTEX SET STRING SUM TEMP_TABLE TO TO_CSV TO_DATETIME TRIM TRUE TUPLE UINT UPDATE VALUES VERTEX WHERE AVG ABORT ACL API ATTRIBUTE CONCAT DATA DATA_SOURCE DEFINE DIRECTED EMPTY EXPORT FILENAME FLATTEN FLATTEN_JSON_ARRAY GET GRANT IMPORT INSTALL JSON LOADING LOCAL LS MAX MIN MINUS OVERWRITE OWNER PAIR PASSWORD PRIVILEGE PUT READ REJECT_LINE_RULE RESUME REVOKE ROLE RUN SCHEMA SCHEMA_CHANGE SECONDARY_ID SECRET SEPARATOR SHOW SPLIT STATS STATUS STORE SUBSTR SYNTAX TAG TEMPLATE TOKEN TOKEN_LEN TOKENBANK TO_FLOAT TO_INT UNDIRECTED USE USER JSONOBJECT JSONARRAY GSQLScript QueryBlock CreateQueryModifiers UseGraphStmt RunQueryStmt RunOptions ParameterValueList ParameterConstant BagOrSet VertexValue _ ParameterValueJSON ParameterValueJSONPair InstallQueryStmt InstallOptions * DropQueryStmt ShowQueryStmt",
  maxTerm: 190,
  nodeProps: [
    ["isolate", -3,2,3,4,""]
  ],
  skippedNodes: [0,3,4,13,15,16,18,19,20,21,22,23,24,25,26,27,28,29,31,32,33,34,35,36,37,38,39,42,43,44,45,46,47,48,49,50,51,52,54,55,56,57,58,59,60,62,63,64,65,66,67,68,69,72,73,75,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,133,134,135,136,137,139,140,141,142,143,144,145,146,147,148,149,150,151,152,154,155,156],
  repeatNodeCount: 5,
  tokenData: "!j~RXXYnYZn]^npqnz{!P|}!U}!O!Z![!]!`#R#S!e~sS$|~XYnYZn]^npqn~!UO$r~~!ZO%R~~!`O%O~~!eO%T~~!jO$m~",
  tokenizers: [queryTokens, tokens, 0],
  topRules: {"GSQLScript":[0,157]},
  specialized: [{term: 14, get: (value, stack) => (keywords(value, stack) << 1), external: keywords}],
  tokenPrec: 0
})
