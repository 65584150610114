export const baseTypes = [
  'INT',
  'UINT',
  'FLOAT',
  'DOUBLE',
  'STRING',
  'DATETIME',
  'BOOL',
  'VERTEX',
  'EDGE',
  'JSONOBJECT',
  'JSONARRAY',
  'SET',
  'BAG',
  'FILE',
  'DATETIME',
];
export const containerTypes = ['SET', 'BAG', 'LIST', 'MAP'];

export const accums = [
  'AvgAccum',
  'SumAccum',
  'MaxAccum',
  'MinAccum',
  'OrAccum',
  'AndAccum',
  'BitwiseOrAccum',
  'BitwiseAndAccum',
  'ListAccum',
  'SetAccum',
  'BagAccum',
  'MapAccum',
  'ArrayAccum',
  'HeapAccum',
  'GroupByAccum',
];

export const controlKeywords = [
  // done: begin at the query statement
  'IF',
  'WHILE',
  'FOREACH',
  'CASE',
  'TRY',
  'RAISE',
  'BREAK',
  'WHEN',
  'CONTINUE',
  // after if
  'THEN',
  'ELSE',
  'END',
  // after while
  'DO',
  // after try
  'EXCEPTION',
];

export const stmtKeywordsMap: Record<string, string[]> = {
  SelectStmt: [
    'FROM',
    'WHERE',
    'ACCUM',
    'POST-ACCUM',
    'HAVING',
    'LIMIT',
    'SAMPLE',
    'WHEN',
    'EDGE',
    'TARGET',
    'PINNED',
    'ORDER',
    'BY',
    'ASC',
    'DESC',
    'OFFSET',
    'INTO',
    'AS',
    'DISTINCT',
    'IN',
    'GROUP',
    'ANY',
  ],
  IfStmt: ['THEN', 'ELSE', 'END', 'ELSE IF'],
  DmlSubIfStmt: ['THEN', 'ELSE', 'END', 'ELSE IF'],
  ForEachStmt: ['IN', 'RANGE', 'DO', 'END'],
  DmlSubForEachStmt: ['IN', 'RANGE', 'DO', 'END'],
  WhileStmt: ['LIMIT', 'DO', 'END'],
  DmlSubWhileStmt: ['LIMIT', 'DO', 'END'],
  TryStmt: ['EXCEPTION', 'WHEN', 'THEN', 'ELSE', 'END'],
  InsertStmt: ['INTO', 'VALUES', 'PRIMARY_ID', 'FROM', 'TO'],
  CaseStmt: ['WHEN', 'THEN', 'ELSE', 'END'],
  DmlSubCaseStmt: ['WHEN', 'THEN', 'ELSE', 'END'],
  UpdateStmt: ['FROM', 'SET', 'WHERE'],
  DeleteStmt: ['FROM', 'WHERE'],
  TypedefStmt: ['TUPLE'],
  PrintStmt: ['AS'],
};

export const StmtNames = Object.keys(stmtKeywordsMap);

export const keywords = [
  // done: outside query body
  'INTERPRET',
  'CREATE',
  'DISTRIBUTED',
  'FOR',
  'REPLACE',
  'QUERY',
  'GRAPH',
  'API',
  'RETURNS',
  'SYNTAX',
  // done: begin at the query statement
  'PRINT',
  'LOG',
  'RETURN',
  'SELECT',
  'INSERT',
  'UPDATE',
  'DELETE',
  'TYPEDEF',
  // done: after typedef
  'TUPLE',
  // done: after select
  'FROM',
  'WHERE',
  'ACCUM',
  'POST-ACCUM',
  'HAVING',
  'LIMIT',
  'SAMPLE',
  'WHEN',
  'EDGE',
  'TARGET',
  'PINNED',
  'ORDER',
  'BY',
  'ASC',
  'DESC',
  'OFFSET',
  'AS',
  'DISTINCT',
  'POST_ACCUM',
  'GROUP',
  // done: after insert
  'INTO',
  'TO',
  'VALUES',
  // done: after foreach
  'RANGE',
  // after .
  'FILTER',
  // in path or vertex set
  'ANY',
];

export const atoms = ['GSQL_UINT_MAX', 'GSQL_INT_MAX', 'GSQL_INT_MIN', 'TO_DATETIME', 'TRUE', 'FALSE', 'NULL'];

export const builtInAttrs = ['type'];

export const containerFuncs = ['size'];
export const jsonObjFuncs = [
  'containsKey',
  'getInt',
  'getDouble',
  'getString',
  'getBool',
  'getJsonObject',
  'getJsonArray',
];
export const jsonArrFuncs = ['getInt', 'getDouble', 'getString', 'getBool', 'getJsonObject', 'getJsonArray', 'size'];
export const fileFuncs = ['println'];
export const graphFuncs = [
  'outdegree',
  'neighbors',
  'neighborAttribute',
  'edgeAttribute',
  'edgeAttribute',
  'getAttr',
  'setAttr',
  'isDirected',
  'selectVertex',
  'getvid',
];
export const globalFuncs = [
  // math functions
  'abs',
  'sqrt',
  'pow',
  'acos',
  'asin',
  'atan',
  'atan2',
  'ceil',
  'cos',
  'cosh',
  'exp',
  'floor',
  'fmod',
  'ldexp',
  'log',
  'log10',
  'sin',
  'sinh',
  'tan',
  'tanh',
  'to_string',
  'float_to_int',
  'str_to_int',
  'lower',
  'upper',
  'trim',
  'to_datetime',
  'epoch_to_datetime',
  'datetime_to_epoch',
  'datetime_format',
  'now',
  'year',
  'month',
  'day',
  'hour',
  'minute',
  'second',
  'datetime_add',
  'datetime_sub',
  'datetime_diff',
  'parse_json_object',
  'parse_json_array',
  'coalesce',
  'evaluate',
  'to_vertex',
  'to_vertex_set',
  'getvid',
];

export const operators = [
  'NOT',
  'AND',
  'OR',
  'BETWEEN',
  'IS NULL',
  'IS NOT NULL',
  'LIKE',
  'ESCAPE',
  'UNION',
  'INTERSECT',
  'MINUS',
  'IN',
];
