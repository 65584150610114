// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  QueryText = 1,
  String = 2,
  BlockComment = 3,
  LineComment = 4,
  Number = 5,
  Bool = 6,
  ParenL = 7,
  ParenR = 8,
  BraceL = 9,
  BraceR = 10,
  BracketL = 11,
  BracketR = 12,
  Dot = 13,
  Identifier = 14,
  ACCUM = 15,
  ADD = 16,
  ALL = 17,
  ALLOCATE = 18,
  ALTER = 19,
  ANY = 20,
  ASC = 21,
  BAG = 22,
  BETWEEN = 23,
  BOOL = 24,
  BY = 25,
  CALL = 26,
  COALESCE = 27,
  COMPRESS = 28,
  COUNT = 29,
  CREATE = 30,
  KAFKA = 31,
  S3 = 32,
  DATETIME_ADD = 33,
  DATETIME_SUB = 34,
  DATETIME_DIFF = 35,
  DATETIME_TO_EPOCH = 36,
  DATETIME_FORMAT = 37,
  DELETE = 38,
  DESC = 39,
  DISTRIBUTED = 40,
  DROP = 41,
  DATETIME = 42,
  DOUBLE = 43,
  EPOCH_TO_DATETIME = 44,
  EDGE = 45,
  ESCAPE = 46,
  FALSE = 47,
  FILE = 48,
  FIXED_BINARY = 49,
  FLOAT = 50,
  FROM = 51,
  GLOBAL = 52,
  GRAPH = 53,
  GROUP = 54,
  HAVING = 55,
  HEADER = 56,
  INDEX = 57,
  INPUT_LINE_FILTER = 58,
  INSERT = 59,
  INT = 60,
  INTERPRET = 61,
  INTO = 62,
  ISEMPTY = 63,
  JOB = 64,
  LIKE = 65,
  LIMIT = 66,
  NOT = 67,
  NOW = 68,
  NULL = 69,
  OPENCYPHER = 70,
  OR = 71,
  ORDER = 72,
  PRIMARY_ID = 73,
  QUERY = 74,
  REDUCE = 75,
  REPLACE = 76,
  SAMPLE = 77,
  SELECT = 78,
  SELECTVERTEX = 79,
  SET = 80,
  STRING = 81,
  SUM = 82,
  TEMP_TABLE = 83,
  TO = 84,
  TO_CSV = 85,
  TO_DATETIME = 86,
  TRIM = 87,
  TRUE = 88,
  TUPLE = 89,
  UINT = 90,
  UPDATE = 91,
  VALUES = 92,
  VERTEX = 93,
  WHERE = 94,
  AVG = 95,
  ABORT = 96,
  ACL = 97,
  API = 98,
  ATTRIBUTE = 99,
  CONCAT = 100,
  DATA = 101,
  DATA_SOURCE = 102,
  DEFINE = 103,
  DIRECTED = 104,
  EMPTY = 105,
  EXPORT = 106,
  FILENAME = 107,
  FLATTEN = 108,
  FLATTEN_JSON_ARRAY = 109,
  GET = 110,
  GRANT = 111,
  IMPORT = 112,
  INSTALL = 113,
  JSON = 114,
  LOADING = 115,
  LOCAL = 116,
  LS = 117,
  MAX = 118,
  MIN = 119,
  MINUS = 120,
  OVERWRITE = 121,
  OWNER = 122,
  PAIR = 123,
  PASSWORD = 124,
  PRIVILEGE = 125,
  PUT = 126,
  READ = 127,
  REJECT_LINE_RULE = 128,
  RESUME = 129,
  REVOKE = 130,
  ROLE = 131,
  RUN = 132,
  SCHEMA = 133,
  SCHEMA_CHANGE = 134,
  SECONDARY_ID = 135,
  SECRET = 136,
  SEPARATOR = 137,
  SHOW = 138,
  SPLIT = 139,
  STATS = 140,
  STATUS = 141,
  STORE = 142,
  SUBSTR = 143,
  SYNTAX = 144,
  TAG = 145,
  TEMPLATE = 146,
  TOKEN = 147,
  TOKEN_LEN = 148,
  TOKENBANK = 149,
  TO_FLOAT = 150,
  TO_INT = 151,
  UNDIRECTED = 152,
  USE = 153,
  USER = 154,
  JSONOBJECT = 155,
  JSONARRAY = 156,
  GSQLScript = 157,
  QueryBlock = 158,
  CreateQueryModifiers = 159,
  UseGraphStmt = 160,
  RunQueryStmt = 161,
  RunOptions = 162,
  ParameterValueList = 163,
  ParameterConstant = 164,
  ParameterValueJSON = 168,
  ParameterValueJSONPair = 169,
  InstallQueryStmt = 170,
  InstallOptions = 171
