import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useEffect, useState } from 'react';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { STYLE_TYPE } from 'baseui/checkbox';
import { InlineBlock } from '@/pages/admin/user/styled';
import { ParagraphMedium } from 'baseui/typography';
import { Input } from '@tigergraph/app-ui-lib/input';
import { RestoreDrawer } from '@/pages/workgroup/tab/backupRestore/RestoreDrawer';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axiosController } from '@/lib/network';
import { Block } from 'baseui/block';
import { Button } from '@tigergraph/app-ui-lib/button';
import { BackupSchedule, WorkGroupT, WorkspaceT } from '@/pages/workgroup/type';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { TableBuilderColumn } from 'baseui/table-semantic';
import { Backup, DeleteBackup } from '@/pages/home/icons';
import { bytesToSize, getErrorMessage } from '@/utils/utils';
import { showToast } from '@/components/styledToasterContainer';
import { BaseSkeleton } from '@/components/BaseSkeleton';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@tigergraph/app-ui-lib/modal';
import { registerOptionPreset } from 'cron-validate/lib/option';
import cron from 'cron-validate';

import { format } from 'date-fns';
import { expand } from 'inline-style-expand-shorthand';
import { Desc } from '@/pages/workgroup/form/StyledComponent';
import { AxiosError } from 'axios';
import { parseDate } from '@/lib/date';
import ErrorIcon from './icon/error.svg?react';
import SuccessIcon from './icon/success.svg?react';
import WaitIcon from './icon/wait.svg?react';
import { Spinner } from '@tigergraph/app-ui-lib/spinner';
import TooltipLabel from '@/components/TooltipLabel';

const cronValidate = (str: string) => {
  registerOptionPreset('my-preset', {
    presetId: 'my-preset',
    useSeconds: false,
    useYears: false,
    useAliases: true,
    useBlankDay: false,
    allowOnlyOneBlankDayField: false,
    mustHaveBlankDayField: false,
    useLastDayOfMonth: false,
    useLastDayOfWeek: false,
    useNearestWeekday: false,
    useNthWeekdayOfMonth: false,
    seconds: {
      minValue: 0,
      maxValue: 59,
    },
    minutes: {
      minValue: 0,
      maxValue: 59,
    },
    hours: {
      minValue: 0,
      maxValue: 23,
    },
    daysOfMonth: {
      minValue: 1,
      maxValue: 31,
    },
    months: {
      minValue: 1,
      maxValue: 12,
    },
    daysOfWeek: {
      minValue: 0,
      maxValue: 6,
    },
    years: {
      minValue: 1970,
      maxValue: 2099,
    },
  });

  return cron(str, { preset: 'my-preset' });
};

export interface BackupRestoreProps {
  workgroup: WorkGroupT;
  workspace: WorkspaceT;
}

export interface BackupT {
  tag: string;
  size_bytes: string;
  type: string;
  status: BackupStatus;
  time: string;
  id: string;
  is_automatic: boolean;
}

export enum BackupStatus {
  BACKUP_FAILED = 'Failed',
  BACKUP_SUCCEED = 'Succeed',
  BACKUP_RETRY = 'Retrying',
  BACKUP_ACTIVE = 'Active',
  BACKUP_WAIT = 'Waiting',
  BACKUP_FORBIDDEN = 'Forbidden', // The TG version is not supported or some configurations are missing.
  BACKUP_SCHEDULED = 'Scheduled',
}

export function WorkspaceBackup({ workspace, workgroup }: BackupRestoreProps) {
  const [css, theme] = useStyletron();
  const [isRestoreDrawerOpen, setIsRestoreDrawerOpen] = useState(false);
  const [data, setData] = useState<BackupT[]>([]);
  const [currentBackup, setCurrentBackup] = useState<BackupT | null>(null);
  const [isSchedulePause, setIsSchedulePause] = useState(false);
  const [scheduleString, setScheduleString] = useState('');
  const [hour, setHour] = useState('0');
  const [dayOfMonth, setDayOfMonth] = useState('*');
  const [month, setMonth] = useState('*');
  const [dayOfWeek, setDayOfWeek] = useState('*');
  const [showModal, setShowModal] = useState(false);
  const [backupId, setBackupId] = useState('');
  const queryClient = useQueryClient();

  const workspaceId = workspace.workspace_id;
  const workgroupId = workgroup.workgroup_id;

  // Failed, Forbidden -> Error
  // Succeed -> Succeed
  // Active, Retry -> In Progress
  // Wait, Scheduled -> Wait
  const statusColorMap = {
    [BackupStatus.BACKUP_FAILED]: <ErrorIcon />,
    [BackupStatus.BACKUP_SUCCEED]: <SuccessIcon />,
    [BackupStatus.BACKUP_RETRY]: <Spinner $size={'16px'} $borderWidth={'2px'} $color={theme.colors.gray1000} />,
    [BackupStatus.BACKUP_ACTIVE]: <Spinner $size={'16px'} $borderWidth={'2px'} $color={theme.colors.gray1000} />,
    [BackupStatus.BACKUP_WAIT]: <WaitIcon />,
    [BackupStatus.BACKUP_FORBIDDEN]: <ErrorIcon />,
    [BackupStatus.BACKUP_SCHEDULED]: <WaitIcon />,
  };

  const timeTypes = [
    {
      name: 'Hour',
      range: [[0, 23]],
      type: 'hour',
    },
    {
      name: 'Day of Month',
      range: [[1, 31]],
      type: 'dayOfMonth',
    },
    {
      name: 'Month',
      range: [
        [1, 12],
        ['Jan', 'Dec'],
      ],
      type: 'month',
    },
    {
      name: 'Day of Week',
      range: [
        [0, 6],
        ['Mon', 'Sun'],
      ],
      type: 'dayOfWeek',
    },
  ];

  useEffect(() => {
    // Combined schedule string
    // minutes default to 0 and not allow end user to change
    setScheduleString(`0 ${hour.trim()} ${dayOfMonth.trim()} ${month.trim()} ${dayOfWeek.trim()}`);
  }, [hour, dayOfMonth, month, dayOfWeek]);

  const { isFetching } = useQuery(
    ['getBackupList', workspaceId],
    async () => {
      const response = await axiosController.get(`/v2/workgroups/${workgroupId}/workspaces/${workspaceId}/backups`);
      return response.data;
    },
    {
      onSuccess: (data) => {
        setData(data?.Result ?? []);
      },
      enabled: !!workspaceId && !!workgroupId,
    }
  );

  const queryScheduleClient = useQuery(
    ['getBackupSchedule', workspaceId],
    async () => {
      const response = await axiosController.get(
        `/v2/workgroups/${workgroupId}/workspaces/${workspaceId}/backups/schedule`
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        setIsSchedulePause(data?.Result?.pause ?? true);
        const str = data?.Result?.schedule ? data?.Result?.schedule : '0 0 * * *';
        const scheduleArray = str.split(' ');
        setHour(scheduleArray[1]);
        setDayOfMonth(scheduleArray[2]);
        setMonth(scheduleArray[3]);
        setDayOfWeek(scheduleArray[4]);
      },
      enabled: !!workspaceId && !!workgroupId,
    }
  );

  const { mutate: saveSchedule, isLoading } = useMutation(
    ['saveSchedule'],
    async (schedule: BackupSchedule) => {
      const response = await axiosController.post(
        `/v2/workgroups/${workgroupId}/workspaces/${workspaceId}/backups/schedule`,
        schedule
      );
      return response.data;
    },
    {
      onError: (error: AxiosError<any, any>) => {
        showToast({
          kind: 'negative',
          message: getErrorMessage(error),
        });
      },
      onSuccess: () => {
        showToast({
          kind: 'positive',
          message: 'Schedule saved successfully.',
        });
      },
    }
  );

  const { mutate: deleteBackup, isLoading: deleteLoading } = useMutation(
    ['deleteBackup'],
    async (backupId: string) => {
      const response = await axiosController.delete(`/v2/workgroups/${workgroupId}/workspaces/${workspaceId}/backups`, {
        data: {
          backup_id: backupId,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        showToast({
          kind: 'positive',
          message: 'Backup deleted successfully.',
        });
        setShowModal(false);
        queryClient.fetchQuery(['getBackupList', workspaceId]);
        queryClient.invalidateQueries(['group', workgroupId]);
      },
    }
  );

  const cronResult = cronValidate(scheduleString);
  let errorMsg = '';

  // keep consistent with the admin portal.
  if (cronResult.isError()) {
    const separator = ' (Input cron';

    const formattedErrors = cronResult.getError().map((error) => {
      console.log(error);
      if (error.startsWith('Element')) {
        const arr = error.split(separator)[0].split(' ');

        // Replace the text and add the pairing single quote.
        arr[0] = arr[0].replace('Element', 'String');
        arr[1] += `'`;

        return arr.join(' ');
      } else if (error.startsWith('Number')) {
        const arr = error.split(separator)[0].split(' ');

        // Add a pair of single quotes around the number.
        arr[1] = `'${arr[1]}'`;

        return arr.join(' ') + '.';
      }

      return error.split(separator)[0];
    });

    errorMsg = formattedErrors.join('\n');
  }

  const TimeInput = (name: string, index: number) => {
    const [css] = useStyletron();

    const type = timeTypes.find((t) => t.name === name)!;

    const value = index === 0 ? hour : index === 1 ? dayOfMonth : index === 2 ? month : dayOfWeek;

    return (
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
        })}
        key={index}
      >
        <span
          className={css({
            fontSize: '14px',
            whiteSpace: 'nowrap',
          })}
        >
          {type.name}
        </span>
        <Input
          placeholder={'0'}
          onChange={(e) => {
            if (index === 0) {
              setHour(e.currentTarget.value);
            } else if (index === 1) {
              setDayOfMonth(e.currentTarget.value);
            } else if (index === 2) {
              setMonth(e.currentTarget.value);
            } else if (index === 3) {
              setDayOfWeek(e.currentTarget.value);
            }
          }}
          value={value}
          overrides={{
            Root: {
              style: {
                marginTop: '8px',
                marginBottom: '8px',
              },
            },
          }}
        />
        <span
          className={css({
            fontSize: '12px',
            color: `${theme.colors['text.secondary']}`,
          })}
        >
          {type.range.map((r) => `${r[0]}-${r[1]}`).join(', ')}, *
        </span>
      </div>
    );
  };

  const handleOpenRestoreDrawer = (backupId: string) => {
    setCurrentBackup(data.find((d) => d.tag === backupId)!);
    setIsRestoreDrawerOpen(true);
  };

  const handleSetSchedulePause = (isPause: boolean) => {
    setIsSchedulePause(isPause);
    saveSchedule({
      pause: isPause,
      schedule: scheduleString,
    });
  };

  const tableLoading = () => {
    return (
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        })}
      >
        <BaseSkeleton height="32px" width="200px" />
        <BaseSkeleton height="32px" width="240px" />
        <BaseSkeleton height="32px" width="350px" />
        <BaseSkeleton height="32px" width="200px" />
        <BaseSkeleton height="32px" width="160px" />
        <BaseSkeleton height="32px" width="300px" />
        <BaseSkeleton height="32px" width="500px" />
        <BaseSkeleton height="32px" width="600px" />
      </div>
    );
  };

  return (
    <>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',

          paddingLeft: '12px',
          paddingRight: '12px',
          paddingTop: '8px',
          paddingBottom: '8px',
          height: 'calc(100% - 50px)',
        })}
      >
        <TableBuilder
          data={data}
          isLoading={isFetching}
          loadingMessage={tableLoading}
          emptyMessage={'No data'}
          overrides={{
            Root: {
              style: {
                maxHeight: '60%',
              },
            },
          }}
        >
          <TableBuilderColumn header="Backup Name" id="tag">
            {(row) => <>{row.tag}</>}
          </TableBuilderColumn>
          <TableBuilderColumn header="Type" id="type">
            {(row) => <>{row.is_automatic ? 'Auto' : 'Manual'}</>}
          </TableBuilderColumn>
          <TableBuilderColumn header="Status" id="status">
            {(row) => statusColorMap[row.status as BackupStatus]}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={
              <TooltipLabel
                label="Size (IDS Only)"
                tooltip="This size only includes IDS data. It does not reflect the total backup size, which will include both graph store and IDS data in the future."
              />
            }
            id="size"
          >
            {(row) => <>{row.size_bytes ? bytesToSize(row.size_bytes) : '-'}</>}
          </TableBuilderColumn>
          <TableBuilderColumn header="Time" id="time">
            {(row) => <>{row.time ? format(parseDate(row.time), 'yyyy-MM-dd HH:mm:ss') : '-'}</>}
          </TableBuilderColumn>
          <TableBuilderColumn header="" id="operation">
            {(row) => (
              <>
                {row.status === BackupStatus.BACKUP_SUCCEED && (
                  <Button
                    overrides={{
                      BaseButton: {
                        style: {
                          marginRight: '4px',
                        },
                      },
                    }}
                    kind="text"
                    shape="square"
                    onClick={() => {
                      handleOpenRestoreDrawer(row.tag);
                    }}
                  >
                    <Backup />
                  </Button>
                )}

                <Button
                  kind="text"
                  shape="square"
                  onClick={() => {
                    setShowModal(true);
                    setBackupId(row.id);
                  }}
                  overrides={{
                    BaseButton: {
                      style: {
                        color: theme.colors['icon.danger'],
                      },
                    },
                  }}
                >
                  <DeleteBackup />
                </Button>
              </>
            )}
          </TableBuilderColumn>
        </TableBuilder>

        <Checkbox
          containsInteractiveElement={true}
          checkmarkType={STYLE_TYPE.toggle}
          labelPlacement="right"
          checked={!isSchedulePause}
          onChange={() => {
            handleSetSchedulePause(!isSchedulePause);
          }}
          overrides={{
            Root: {
              style: {
                alignItems: 'center',
              },
            },
          }}
        >
          <ParagraphMedium as="div">
            <InlineBlock style={{ alignItems: 'center', gap: '8px' }}>Enable Scheduled Backup</InlineBlock>
          </ParagraphMedium>
        </Checkbox>
        <Desc
          $style={{
            marginTop: '1px',
            marginBottom: '24px',
          }}
        >
          Scheduled backup enables you to setup backup in cron job. If the maximum retention number is reached when a
          new backup is made, the oldest scheduled backup will be deleted. The backup schedule can be customized.
        </Desc>
        <div
          className={css({
            display: 'flex',
            gap: '16px',
            minWidth: '100%',
            overflow: 'auto',
          })}
        >
          {timeTypes.map((t, index) => TimeInput(t.name, index))}
        </div>

        {errorMsg && !queryScheduleClient.isLoading && (
          <div
            className={css({
              color: theme.colors.negative,
              fontSize: '12px',
            })}
          >
            {errorMsg}
          </div>
        )}

        <Block>
          <Button
            disabled={!!errorMsg}
            isLoading={isLoading}
            overrides={{
              BaseButton: {
                style: {
                  height: '32px',
                  fontSize: '14px',
                  ...expand({
                    borderRadius: '2px',
                  }),
                },
              },
            }}
            onClick={() => {
              saveSchedule({
                pause: isSchedulePause,
                schedule: scheduleString,
              });
            }}
          >
            Save
          </Button>
        </Block>

        <div
          className={css({
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          })}
        >
          <div>
            * Test your cron in{' '}
            <a
              className={css({
                color: `${theme.colors.secondary800}`,
              })}
              href={`https://crontab.guru/#${`0_${hour}_${dayOfMonth}_${month}_${dayOfWeek}`}`}
              target="_blank"
              rel="noreferrer"
            >
              Crontab Guru
            </a>
          </div>
          <div>
            * Set the{' '}
            <a
              className={css({
                color: `${theme.colors.secondary800}`,
              })}
              href={'https://en.wikipedia.org/wiki/Cron'}
              target="_blank"
              rel="noreferrer"
            >
              cron
            </a>{' '}
            in UTC timezone!
          </div>
        </div>
      </div>
      {isRestoreDrawerOpen && (
        <RestoreDrawer
          isOpen={isRestoreDrawerOpen}
          onClose={() => {
            setCurrentBackup(null);
            setIsRestoreDrawerOpen(false);
          }}
          workspace={workspace}
          workgroup={workgroup}
          backup={currentBackup}
          onRestoreSuccess={() => {
            showToast({
              kind: 'positive',
              message: 'Restore successfully.',
            });
            setIsRestoreDrawerOpen(false);
          }}
        />
      )}
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalHeader>Delete Backup</ModalHeader>
        <ModalBody>
          <div>
            Delete will permanently remove backup file <b>{backupId}</b> and cannot be recovered.
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalButton kind="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </ModalButton>
          <ModalButton
            isLoading={deleteLoading}
            onClick={() => {
              deleteBackup(backupId);
            }}
          >
            Delete
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
