import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { ArrowDown, ArrowUp } from '@/pages/home/icons.tsx';
import { useEffect, useState } from 'react';
import IconButton from '@/components/IconButton.tsx';
import { Input } from '@tigergraph/app-ui-lib/input';
import ReactCodeMirror from '@uiw/react-codemirror';
import { xcodeDark, xcodeLight } from '@uiw/codemirror-theme-xcode';
import useCopyClipboard from 'react-use-clipboard';
import { showToast } from '@/components/styledToasterContainer';
import { ApiType, cmOptions } from '@/pages/workgroup/tab/restPP/type.ts';
import { ApiSetting } from '@/pages/workgroup/tab/restPP/ApiSetting.tsx';
import { generateCurl, generateJavascript, generatePython, Heading } from '@/pages/workgroup/tab/restPP/Util.tsx';
import { useMutation, useQuery } from 'react-query';
import { TableBuilderColumn } from 'baseui/table-semantic';
import { TableBuilder } from '@tigergraph/app-ui-lib/table';
import { Button } from '@tigergraph/app-ui-lib/button';
import { axiosCluster } from '@/lib/network.ts';
import { StreamLanguage } from '@codemirror/language';
import { json } from '@codemirror/legacy-modes/mode/javascript';
import { expand } from 'inline-style-expand-shorthand';
import { getErrorMessage } from '@/utils/utils.ts';
import { AxiosError } from 'axios';
import { ID_TOKEN_KEY } from '@/contexts/workspaceContext.tsx';
import { useTheme } from '@/contexts/themeContext';
import { MdContentCopy } from 'react-icons/md';
import { GsqlParameter, QueryMetaLogic, QueryParam } from '@tigergraph/tools-models';
import { getURLSearchParamsForQuery } from '@/pages/editor/query/util';

export interface ApiContainerProps {
  apiName: string;
  apiInfo: Record<string, GsqlParameter>;
  baseUrl: string;
  graphName: string;
}

export function ApiContainer({ apiName, apiInfo, baseUrl, graphName }: ApiContainerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [urlCopied, setUrlCopied] = useCopyClipboard(baseUrl, {
    successDuration: 1000,
  });
  const [pathParams, setPathParams] = useState<{ name: string; value: string }[]>([]);
  const [queryParams, setQueryParams] = useState<QueryParam[]>([]);
  const [css, theme] = useStyletron();
  // request payload is JSON string
  const [payloadText, setPayloadText] = useState<string>('{}');
  const [response, setResponse] = useState<{ [key: string]: any }>({});
  const [curlCommand, setCurlCommand] = useState('');
  const [javascriptCommand, setJavascriptCommand] = useState('');
  const [pythonCommand, setPythonCommand] = useState('');
  const [isQueryParamsValid, setIsQueryParamsValid] = useState(true);

  // set queryParams and pathParams
  useEffect(() => {
    const pattern = /{([^}]+)}/g;
    const matches = apiName.split(' ')[1].matchAll(pattern);
    let pathParams: { name: string; value: string }[] = [];
    for (const match of matches) {
      if (match[1] !== 'graph_name') {
        pathParams.push({ name: match[1], value: '' });
      } else {
        pathParams.push({ name: match[1], value: graphName });
      }
    }
    setPathParams(pathParams);

    // the parameters in apiInfo.parameters except path parameters are query parameters
    const filteredParams: Record<string, GsqlParameter> = {};
    if (apiInfo) {
      for (const param of Object.keys(apiInfo)) {
        if (!pathParams.find((p) => p.name === param)) {
          filteredParams[param] = apiInfo[param];
        }
      }
      const queryParams = QueryMetaLogic.convertGSQLParameters(filteredParams);
      setQueryParams(queryParams);
    }
  }, [apiInfo, apiName, graphName]);

  useEffect(() => {
    if (urlCopied) {
      showToast({
        kind: 'positive',
        message: 'Request url copied successfully.',
      });
    }
  }, [urlCopied]);

  const apiType: ApiType = apiName.split(' ')[0] as ApiType;
  const apiPath = apiName.split(' ')[1];

  useEffect(() => {
    const getModifiedPath = () => {
      let index = 0;
      return apiPath.replace(/{([^}]+)}/g, function (match, content) {
        const value = pathParams[index]?.value;
        index++;
        return value;
      });
    };

    let url = '';
    let payload = {};
    let curlCommand = '';
    let javascriptCommand = '';
    let pythonCommand = '';
    try {
      payload = JSON.parse(payloadText);
      setIsQueryParamsValid(true);
    } catch (e) {
      setIsQueryParamsValid(false);
    }

    if (apiType === ApiType.GET) {
      url = `${baseUrl}/api/restpp${getModifiedPath()}?${getURLSearchParamsForQuery(graphName, queryParams, payload)}`;
      const config = {
        url,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
          Accept: 'application/json, text/plain, */*',
          ContentType: 'application/json',
        },
      };
      curlCommand = generateCurl(config);
      javascriptCommand = generateJavascript(config);
      pythonCommand = generatePython(config);
    } else if (apiType === ApiType.POST) {
      url = `${baseUrl}/api/restpp${getModifiedPath()}?graph=${graphName}`;
      const config = {
        url,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
          Accept: 'application/json, text/plain, */*',
          ContentType: 'application/json',
        },
        data: JSON.stringify(payload),
      };
      curlCommand = generateCurl(config);
      javascriptCommand = generateJavascript(config);
      pythonCommand = generatePython(config);
    } else if (apiType === ApiType.DELETE) {
      url = `${baseUrl}/api/restpp${getModifiedPath()}?graph=${graphName}`;
      const config = {
        url,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
          Accept: 'application/json, text/plain, */*',
          ContentType: 'application/json',
        },
        data: JSON.stringify(payload),
      };
      curlCommand = generateCurl(config);
      javascriptCommand = generateJavascript(config);
      pythonCommand = generatePython(config);
    }
    setCurlCommand(curlCommand);
    setJavascriptCommand(javascriptCommand);
    setPythonCommand(pythonCommand);
  }, [apiPath, apiType, baseUrl, pathParams, payloadText, queryParams, graphName]);

  const getClient = useQuery(
    ['queryRestpp', apiName, payloadText, graphName],
    async () => {
      let index = 0;
      const replacementUrl = apiPath.replace(/{([^}]+)}/g, function (match, content) {
        const value = pathParams[index].value;
        index++;
        return value;
      });
      let queryString = '';
      try {
        queryString = getURLSearchParamsForQuery(graphName, queryParams, JSON.parse(payloadText));
      } catch (e) {
        console.error('Failed to parse query code');
      }
      return axiosCluster.get(`${baseUrl}/api/restpp${replacementUrl}?${queryString}`);
    },
    {
      onSuccess: (data) => {
        setResponse(data.data.results);
      },
      onError: (error: AxiosError<any, any>) => {
        showToast({
          kind: 'negative',
          message: getErrorMessage(error),
        });
      },
      enabled: false,
    }
  );

  const postClient = useMutation(
    ['postQueryRestpp', apiName, graphName],
    async (payLoad: string) => {
      const apiPath = apiName.split(' ')[1];
      let index = 0;
      const replacementUrl = apiPath.replace(/{([^}]+)}/g, function (match, content) {
        const value = pathParams[index].value;
        index++;
        return value;
      });
      return axiosCluster.post(`${baseUrl}/api/restpp${replacementUrl}?graph=${graphName}`, payLoad);
    },
    {
      onSuccess: (data) => {
        setResponse(data.data.results);
      },
      onError: (error: AxiosError<any, any>) => {
        showToast({
          kind: 'negative',
          message: getErrorMessage(error),
        });
      },
    }
  );

  const deleteClient = useMutation(
    ['deleteQueryRestpp', apiName, graphName],
    async (payLoad: string) => {
      const apiPath = apiName.split(' ')[1];
      let index = 0;
      const replacementUrl = apiPath.replace(/{([^}]+)}/g, function (match, content) {
        const value = pathParams[index].value;
        index++;
        return value;
      });
      return axiosCluster.delete(`${baseUrl}/api/restpp${replacementUrl}?graph=${graphName}`, {
        data: payLoad,
      });
    },
    {
      onSuccess: (data) => {
        setResponse(data.data.results);
      },
      onError: (error: AxiosError<any, any>) => {
        showToast({
          kind: 'negative',
          message: getErrorMessage(error),
        });
      },
    }
  );

  const handleTest = () => {
    if (!isQueryParamsValid) {
      showToast({
        kind: 'negative',
        message: 'The query string or json payload is invalid. Please check the format.',
      });
      return;
    }
    switch (apiType) {
      case ApiType.GET:
        getClient.refetch();
        break;
      case ApiType.POST: {
        postClient.mutate(JSON.parse(payloadText));
        break;
      }
      case ApiType.PUT:
        break;
      case ApiType.DELETE: {
        deleteClient.mutate(JSON.parse(payloadText));
        break;
      }
      default:
        break;
    }
  };

  const { themeType } = useTheme();

  const backgroundColorMap = {
    [ApiType.GET]: themeType === 'light' ? 'rgba(37, 131, 222, 0.10)' : 'rgba(37, 131, 222, 0.10)',
    [ApiType.POST]: themeType === 'light' ? 'rgba(39, 186, 63, 0.10)' : 'rgba(39, 186, 63, 0.10)',
    [ApiType.PUT]: themeType === 'light' ? 'rgba(248, 173, 104, 0.15)' : 'rgba(248, 173, 104, 0.15)',
    [ApiType.DELETE]: themeType === 'light' ? 'rgba(214, 69, 69, 0.10)' : 'rgba(214, 69, 69, 0.10)',
  };

  const colorMap = {
    [ApiType.GET]: theme.colors['background.informative.subtle'],
    [ApiType.POST]: theme.colors['background.success.bold'],
    [ApiType.PUT]: theme.colors['background.brand.bold'],
    [ApiType.DELETE]: theme.colors['background.danger.subtle'],
  };

  return (
    <div
      className={css({
        backgroundColor: backgroundColorMap[apiType],
        marginBottom: '10px',
        padding: '8px',
        borderRadius: '2px',
        ...expand({
          border: `1px solid ${colorMap[apiType]}`,
        }),
      })}
    >
      <div
        key={apiName}
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          height: '24px',
          alignItems: 'center',
        })}
      >
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            maxWidth: '95%',
          })}
        >
          <div
            className={css({
              backgroundColor: colorMap[apiType],
              display: 'inline-flex',
              marginRight: '8px',
              justifyContent: 'space-around',
              borderRadius: '2px',
              padding: '4px 12px',
              minWidth: '73px',
              color: theme.colors['text.inverse'],
            })}
          >
            {apiName.split(' ')[0]}
          </div>
          <div
            className={css({
              maxWidth: '100%',
              overflow: 'auto',
            })}
          >
            {apiName.split(' ')[1]}
          </div>
        </div>
        <div>
          <Button
            size="compact"
            kind="text"
            shape="square"
            aria-label="api-expand-button"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </Button>
        </div>
      </div>
      {isOpen && (
        <div
          className={css({
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          })}
        >
          <div>
            <Heading>Request URL</Heading>
            <div
              className={css({
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              })}
            >
              <Input disabled value={baseUrl} />
              <IconButton onClick={setUrlCopied}>
                <MdContentCopy size={20} />
              </IconButton>
            </div>
            {/*<a>How to connect</a>*/}
          </div>
          <div>
            <div
              className={css({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              })}
            >
              {pathParams.length ? <Heading>Path params</Heading> : <div></div>}
              <Button
                kind="secondary"
                onClick={() => handleTest()}
                isLoading={getClient.isFetching || postClient.isLoading}
              >
                Test
              </Button>
            </div>
            {pathParams.length > 0 && (
              <TableBuilder
                data={pathParams}
                size="compact"
                overrides={{
                  Root: {
                    style: {
                      backgroundColor: 'transparent',
                    },
                  },
                  TableHeadCell: {
                    style: {
                      backgroundColor: 'transparent',
                      fontSize: '12px',
                    },
                  },
                  TableBodyCell: {
                    style: {
                      verticalAlign: 'middle',
                    },
                  },
                }}
              >
                <TableBuilderColumn header="Name">{(param) => <div>{param.name}</div>}</TableBuilderColumn>
                <TableBuilderColumn header="Value">
                  {(param) => (
                    <Input
                      disabled={param.name === 'graph_name'}
                      value={param.name === 'graph_name' ? graphName : param.value}
                      onChange={(e) => {
                        const newParams = pathParams.map((p) => {
                          if (p.name === param.name) {
                            return { name: p.name, value: e.currentTarget.value };
                          }
                          return p;
                        });
                        setPathParams(newParams);
                      }}
                    />
                  )}
                </TableBuilderColumn>
              </TableBuilder>
            )}
          </div>
          <ApiSetting
            queryParams={queryParams}
            apiType={apiType}
            onQueryCodeChange={(payload) => {
              setPayloadText(payload);
            }}
            curlCommand={curlCommand}
            javascriptCommand={javascriptCommand}
            pythonCommand={pythonCommand}
          />
          <div>
            <Heading>Response</Heading>
            <ReactCodeMirror
              editable={false}
              value={JSON.stringify(response, null, 2)}
              basicSetup={cmOptions}
              theme={themeType === 'light' ? xcodeLight : xcodeDark}
              width={'100%'}
              height={'200px'}
              extensions={[StreamLanguage.define(json)]}
            />
          </div>
        </div>
      )}
    </div>
  );
}
